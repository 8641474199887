import styled from 'styled-components/macro';

import { backgroundImageMixin } from '../utils';
import questionCircle from '../resources/images/question-circle.svg';

const size = '1.8rem';

export const StyledQuestionIcon = styled.div`
    width: ${size};
    height: ${size};
    cursor: help;

    ${backgroundImageMixin({ url: questionCircle, size })}
`;
