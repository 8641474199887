import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledControls = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${lg}) {
        flex-direction: row;
    }

    *:not(:last-child) {
        margin-bottom: 1.4rem;

        @media (min-width: ${lg}) {
            margin-bottom: 0;
            margin-right: 2.6rem;
        }
    }
`;
