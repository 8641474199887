import styled from 'styled-components/macro';

import { backgroundImageMixin } from '../../../utils';
import grayCross from '../../../resources/images/gray-cross.svg';

const buttonSize = '3rem';
const iconSize = '1.9rem';

// STYLING:
const focusOrangeClr = '#947a38';

export const StyledCloseButton = styled.button.attrs({ type: 'button' })`
    ${backgroundImageMixin({ url: grayCross, size: iconSize })}

    width: ${buttonSize};
    height: ${buttonSize};
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    outline: 0;
    background-color: transparent;
    cursor: pointer;
    transition: box-shadow 200ms ease, border-radius 200ms ease;

    &:only-child {
        margin-left: auto;
    }

    &:disabled {
        cursor: default;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
        border-radius: 0;
        box-shadow: 0 0 0 0.3rem ${focusOrangeClr};
    }
`;
