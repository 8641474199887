import styled from 'styled-components/macro';

export const StyledDetailsItem = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 4rem;
    }
`;
