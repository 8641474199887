import { createPortal } from 'react-dom';

import { StyledBackdrop } from './styles';
import { useBodyScrollLock } from '../../hooks';

type Props = {
    portalNode?: HTMLElement;
    isAppearing?: boolean;
    isHiding?: boolean;
};

export const Backdrop = ({ portalNode, ...other }: Props) => {
    useBodyScrollLock();

    return createPortal(
        <StyledBackdrop {...other} />,
        portalNode || document.getElementById('portal-root')!
    );
};
