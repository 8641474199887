import styled from 'styled-components/macro';

import { StyledLabel } from '../../../styles';

export const StyledFieldWrapper = styled.div`
    margin-bottom: 1.2rem;

    ${StyledLabel} {
        display: inline-block;
        margin-bottom: 1.2rem;
    }
`;
