type Options = {
    zeroDigitsAmount?: number;
    decimalDigitsAmount?: number;
};

const DEFAULT_ZERO_DIGITS_AMOUNT = 18;
const DEFAULT_DECIMAL_DIGITS_AMOUNT = 6;

/**
 * @description
 * Get rounded value, assuming that value is a huge number
 *
 * @example
 * ```ts
 * const roundedValue1 = getRoundedValue('1000000000000000000'); // 1.000000
 * const roundedValue2 = getRoundedValue('123000000000000000000'); // 123.000000
 * ```
 */
export const getRoundedValue = (value: string, options: Options = {}) => {
    const {
        decimalDigitsAmount = DEFAULT_DECIMAL_DIGITS_AMOUNT,
        zeroDigitsAmount = DEFAULT_ZERO_DIGITS_AMOUNT
    } = options;

    const decimalIndex = value.length - zeroDigitsAmount;

    let integer;
    let decimal;

    if (decimalIndex > 0) {
        integer = value.slice(0, decimalIndex);
        decimal = value.substr(decimalIndex, decimalDigitsAmount);
    } else {
        const difference = Math.abs(decimalIndex);
        const zeroBarrage = '0'.repeat(difference);
        const decimalDigits = zeroBarrage + value;

        integer = '0';
        decimal = decimalDigits.substr(0, decimalDigitsAmount);
    }

    return [integer, decimal].join('.');
};
