import { AbiItem } from 'web3-utils';

export const APPROVE_METHOD_INTERFACE: AbiItem = {
    name: 'approve',
    type: 'function',
    inputs: [
        {
            type: 'address',
            name: 'spender'
        },
        {
            type: 'uint256',
            name: 'value'
        }
    ]
};

export const ALLOWANCE_METHOD_INTERFACE: AbiItem = {
    name: 'allowance',
    type: 'function',
    inputs: [
        {
            type: 'address',
            name: 'owner'
        },
        {
            type: 'address',
            name: 'spender'
        }
    ]
};

export const BALANCE_METHOD_INTERFACE: AbiItem = {
    name: 'balanceOf',
    type: 'function',
    inputs: [
        {
            type: 'address',
            name: 'account'
        }
    ]
};

export const STAKE_METHOD_INTERFACE: AbiItem = {
    name: 'stake',
    type: 'function',
    inputs: [
        {
            type: 'uint256',
            name: '_amount'
        }
    ]
};

export const UNSTAKE_METHOD_INTERFACE: AbiItem = {
    name: 'unstake',
    type: 'function',
    inputs: [
        {
            type: 'uint256',
            name: '_amount'
        }
    ]
};

export const USER_INFO_METHOD_INTERFACE: AbiItem = {
    name: 'usersInfo',
    type: 'function',
    inputs: [
        {
            type: 'address',
            name: ''
        }
    ]
};

export const POOL_INFO_METHOD_INTERFACE: AbiItem = {
    name: 'getPool',
    type: 'function',
    inputs: []
};

export const GET_DIVIDENDS_METHOD_INTERFACE: AbiItem = {
    name: 'calculateDividends',
    type: 'function',
    inputs: [
        {
            type: 'address',
            name: 'userAddress'
        }
    ]
};

export const WITHDRAW_DIVIDENDS_METHOD_INTERFACE: AbiItem = {
    name: 'getDividends',
    type: 'function',
    inputs: []
};

export const CHECK_UNSTAKE_STATUS_METHOD_INTERFACE: AbiItem = {
    name: 'isTokensFrozen',
    type: 'function',
    inputs: [
        {
            type: 'address',
            name: 'userAddress'
        }
    ]
};

export const DISTRIBUTE_POOL_METHOD_INTERFACE: AbiItem = {
    name: 'distribute',
    type: 'function',
    inputs: []
};

export const MINIMUM_DISTRIBUTE_BALANCE_METHOD_INTERFACE: AbiItem = {
    name: 'minDistrBalance',
    type: 'function',
    inputs: []
};

export const GET_DARS_RATE_METHOD_INTERFACE: AbiItem = {
    name: 'getDarsRate',
    type: 'function',
    inputs: []
};
