import styled, { css } from 'styled-components/macro';

import { ExpandedThemedPropsType } from '../../../theme';
import { backgroundImageMixin } from '../../../utils';
import greenCheck from '../../../resources/images/green-round-check.svg';
import redCross from '../../../resources/images/red-round-cross.svg';

type Props = ExpandedThemedPropsType<{
    active?: boolean;
}>;

// STYLING:
const refinedGreenClr = 'rgba(115, 208, 83, 0.3)';
const refinedRedClr = 'rgba(255, 95, 95, 0.3)';

const applyIcon = ({ active }: Props) => {
    const icon = active ? greenCheck : redCross;
    const shadowClr = active ? refinedGreenClr : refinedRedClr;

    return css`
        ${backgroundImageMixin({ url: icon, size: 'cover' })}

        box-shadow: 0 0.7rem 1.9rem ${shadowClr};
    `;
};

const size = '2.4rem';

export const StyledStatusIcon = styled.div<Props>`
    width: ${size};
    height: ${size};
    position: absolute;
    bottom: 0.3rem;
    right: 0.3rem;
    border-radius: 50%;
    transition: box-shadow 200ms ease;

    ${applyIcon}
`;
