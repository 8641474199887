import styled from 'styled-components/macro';

import { ToastType } from '../Toast';
import { ExpandedThemedPropsType } from '../../../theme';

type Props = ExpandedThemedPropsType<{
    $type: ToastType;
}>;

// STYLING:
const COLORS = {
    success: '#07bc0c',
    error: '#e74c3c'
};

const bgClr = ({ $type }: Props) => COLORS[$type];
const darkClr = ({ theme }: Props) => theme.color.dark[900];
const borderRadius = ({ theme }: Props) => theme.borderRadius.xs;

export const StyledIconWrapper = styled.div<Props>`
    width: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: ${borderRadius} 0 0 ${borderRadius};
    color: ${darkClr};
    background-color: ${bgClr};
`;
