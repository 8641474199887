import styled from 'styled-components/macro';

import { StyledBox } from '../../../styles';
import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledInstructionsBox = styled(StyledBox).attrs({ size: 'md', bg: 'primary' })`
    display: flex;
    flex-direction: column;

    @media (min-width: ${lg}) {
        flex-direction: row;
        justify-content: center;
    }
`;
