import { createContext } from 'react';

import {
    EthereumProvider,
    MetaMaskError,
    GetBalanceOptions,
    TransactionReceiptResponse
} from '../components';
import { ValidChainId } from '../constants';

type MetaMaskContextType = {
    /**
     * Ethereum global object
     */
    ethereum: EthereumProvider | null;
    /**
     * Currently connected account
     */
    account: string | null;
    /**
     * Chain id permitted for connection
     */
    chainId: ValidChainId | null;
    /**
     * Flag, which indicated that MetaMask is installed
     */
    isInstalledMetaMask: boolean;
    /**
     * Flag, which indicates that MetaMask is connected
     */
    isConnectedMetaMask: boolean;
    /**
     * Flag, which indicates that a correct ethereum chain was set
     */
    isCorrectChainId: boolean;
    /**
     * Handler for the error.
     * `onUnknownError` will be called only if error code is not from the list of known codes
     */
    onError: (e: MetaMaskError, onUnknownError: () => void) => void;
    /**
     * Handler to get connected accounts
     */
    onGetAccounts: () => Promise<string[]>;
    /**
     * Handler to connect to MetaMask. During connection it also sets required chain
     */
    onConnectToMetaMask: () => Promise<void>;
    /**
     * Handler to sign message
     */
    onSign: (message: string) => Promise<string>;
    /**
     * Handler for getting transaction receipt
     */
    onGetTransactionReceipt: (txId: string) => Promise<TransactionReceiptResponse | null>;
    /**
     * Handler to get wallet balance
     */
    onGetBalance: (options: GetBalanceOptions) => Promise<{ raw: string; rounded: string }>;
    /**
     * Handler to check if the transaction needs to be approved
     *
     * @returns approvement status and value to approve
     */
    onCheckAllowance: (amount: string) => Promise<{ isApproved: boolean; value: string | null }>;
    /**
     * Handler to approve transaction in USDT smart-contract
     */
    onApprove: (value: string) => Promise<string>;
    /**
     * Handler for staking tokens
     */
    onStakeTokens: (amount: string) => Promise<string>;
    /**
     * Handle for unstaking tokens
     */
    onUnstakeTokens: (amount: string) => Promise<string>;
    /**
     * Handler for getting a user stake info
     */
    onGetUserInfo: () => Promise<{
        freezeDate: number;
        stakedTokens: {
            raw: string;
            rounded: string;
        };
    }>;
    /**
     * Handler for getting dividends amount
     */
    onGetDividends: () => Promise<{ raw: string; rounded: string }>;
    /**
     * Handler for checking unstake status. Returned `true` will mean that
     * tokens are frozen and unstake is disabled
     */
    onCheckUnstakeStatus: () => Promise<boolean>;
    /**
     * Handler for getting a pool info
     */
    onGetPoolInfo: () => Promise<{
        totalStakedTokens: {
            raw: string;
            rounded: string;
        };
        totalDividends: {
            raw: string;
            rounded: string;
        };
        freezingPeriod: number;
    }>;
    /**
     * Handler for withdrawing dividends
     */
    onWithdrawDividends: () => Promise<string>;
    /**
     * Handler for getting a minimum distribute balance
     */
    onGetMinimumDistributeBalance: () => Promise<string>;
    /**
     * Handler for distributing the pool
     */
    onDistributePool: () => Promise<string>;
    /**
     * Handler for checking the pool distribution status. Returned `true` will mean
     * that pool distribution is disabled
     */
    onCheckPoolDistributionStatus: () => Promise<boolean>;
    /**
     * Handler for getting DARS rate
     */
    onGetDarsRate: () => Promise<{ raw: string; rounded: string }>;
};

export const MetaMaskContext = createContext<MetaMaskContextType>({
    ethereum: null,
    account: '0xd278Fb9216a3c424C2138D9313cf34cb6dF10fEC',
    chainId: 56,
    isInstalledMetaMask: false,
    isConnectedMetaMask: false,
    isCorrectChainId: false,
    onError: (e: MetaMaskError, onUnknownError: () => void) => undefined,
    onGetAccounts: () => Promise.resolve(['0xd278Fb9216a3c424C2138D9313cf34cb6dF10fEC']),
    onConnectToMetaMask: () => Promise.resolve(undefined),
    onSign: (message: string) => Promise.resolve('txId'),
    onGetTransactionReceipt: (txId: string) => Promise.resolve(null),
    onGetBalance: (options: GetBalanceOptions) =>
        Promise.resolve({ raw: '50000000000000000000', rounded: '0.000000' }),
    onCheckAllowance: (amount: string) =>
        Promise.resolve({ isApproved: false, value: '50000000000000000000' }),
    onApprove: (value: string) => Promise.resolve('txId'),
    onStakeTokens: (amount: string) => Promise.resolve('txId'),
    onUnstakeTokens: (amount: string) => Promise.resolve('txId'),
    onGetUserInfo: () =>
        Promise.resolve({
            freezeDate: 0,
            stakedTokens: { raw: '50000000000000000000', rounded: '0.000000' }
        }),
    onGetDividends: () => Promise.resolve({ raw: '50000000000000000000', rounded: '0.000000' }),
    onCheckUnstakeStatus: () => Promise.resolve(false),
    onGetPoolInfo: () =>
        Promise.resolve({
            totalStakedTokens: { raw: '50000000000000000000', rounded: '0.00' },
            totalDividends: { raw: '50000000000000000000', rounded: '0.00' },
            freezingPeriod: 3600000
        }),
    onWithdrawDividends: () => Promise.resolve('txId'),
    onDistributePool: () => Promise.resolve('txId'),
    onGetMinimumDistributeBalance: () => Promise.resolve('50000000000000000000'),
    onCheckPoolDistributionStatus: () => Promise.resolve(false),
    onGetDarsRate: () => Promise.resolve({ raw: '50000000000000000000', rounded: '0.000000' })
});
