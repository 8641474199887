import styled from 'styled-components/macro';

import { backgroundImageMixin } from '../../../utils';
import blackCross from '../../../resources/images/black-cross.svg';

const buttonSize = '2.4rem';
const iconSize = '1.2rem';

export const StyledCloseButton = styled.button`
    width: ${buttonSize};
    height: ${buttonSize};
    border: none;
    outline: 0;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    transition: box-shadow 200ms ease, border-radius 200ms ease;

    ${backgroundImageMixin({ url: blackCross, size: iconSize })}

    &:hover,
    &:focus {
        border-radius: 0;
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
    }
`;
