import FocusTrap from 'focus-trap-react';

import { StyledChase, StyledChaseDot, StyledOverlay } from './styles';

export type LoaderSize = 'sm' | 'md' | 'lg';

type FocusTrapOptions = {
    escapeDeactivates?: boolean;
    returnFocusOnDeactivate?: boolean;
    allowOutsideClick?: boolean;
    clickOutsideDeactivates?: boolean;
    preventScroll?: boolean;
};

type Props = {
    /**
     * Loader size
     * @default 'md'
     */
    size?: LoaderSize;
    /**
     * Sets overlay behind loader
     * @default false
     */
    overlay?: boolean;
    /**
     * When true - stretches overlay to screen size
     * and sets opaque background
     * @default false
     */
    fullscreen?: boolean;
    /**
     * Focus trap options
     */
    focusTrapOptions?: FocusTrapOptions;
};

const DEFAULT_FOCUS_TRAP_OPTIONS = {
    escapeDeactivates: false,
    returnFocusOnDeactivate: false,
    allowOutsideClick: true,
    clickOutsideDeactivates: true,
    preventScroll: true
};

export const Loader = ({
    size = 'md',
    fullscreen = false,
    overlay = fullscreen,
    focusTrapOptions
}: Props) => {
    const loader = (
        <FocusTrap focusTrapOptions={{ ...DEFAULT_FOCUS_TRAP_OPTIONS, ...focusTrapOptions }}>
            <StyledChase size={size}>
                <StyledChaseDot />
                <StyledChaseDot />
                <StyledChaseDot />
                <StyledChaseDot />
                <StyledChaseDot />
                <StyledChaseDot />
            </StyledChase>
        </FocusTrap>
    );

    if (overlay) return <StyledOverlay fullscreen={fullscreen}>{loader}</StyledOverlay>;

    return loader;
};
