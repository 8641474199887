import { useState, useEffect, useCallback } from 'react';
import { noop } from 'lodash';

import { useMetaMask } from './useMetaMask';

type Options = {
    interval?: number;
    onSuccess?: (isDisabled: boolean) => void;
    onError?: (e: Error) => void;
};

export const usePoolDistribution = (options: Options = {}) => {
    const { interval, onSuccess = noop, onError = noop } = options;

    const { isConnectedMetaMask, isCorrectChainId, onCheckPoolDistributionStatus } = useMetaMask();

    const [isDisabled, setIsDisabled] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [error, setError] = useState<Error>();
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!isConnectedMetaMask || !isCorrectChainId) return;

        (async () => {
            setIsUpdating(true);

            try {
                const isDisabledPoolDistribution = await onCheckPoolDistributionStatus();

                setIsDisabled(isDisabledPoolDistribution);
                onSuccess(isDisabledPoolDistribution);
            } catch (e) {
                setError(e as Error);
                onError(e);
            } finally {
                setIsUpdating(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, isConnectedMetaMask, isCorrectChainId, onCheckPoolDistributionStatus]);

    // Refresh the balance at a specific time interval
    useEffect(() => {
        if (!interval || !isCorrectChainId) return;

        const timeout = setTimeout(() => {
            setCount(prevCount => prevCount + 1);
        }, interval);

        return () => clearTimeout(timeout);
    }, [count, interval, isCorrectChainId]);

    const handleUpdatePoolDistributionStatus = useCallback(
        () => setCount(prevCount => prevCount + 1),
        []
    );

    return {
        isDisabled: isDisabled || !isCorrectChainId,
        isUpdating,
        error,
        onUpdate: handleUpdatePoolDistributionStatus
    };
};
