import styled from 'styled-components/macro';

import { StyledWrapper as Checkbox } from '../../../components/Checkbox';

export const StyledCheckboxWrapper = styled.div`
    display: flex;
    margin-bottom: 1.2rem;

    ${Checkbox} {
        margin-right: 1.2rem;
    }
`;
