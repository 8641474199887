import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StakingPage } from '../../pages';
import {
    useBalances,
    usePollTransactionStatus,
    useMetaMask,
    useToast,
    usePoolDistribution
} from '../../hooks';
import { convertNumberToUint256 } from '../../utils';
import { MetaMaskError } from '../../components';

type Props = {
    as?: typeof StakingPage;
};

type OperationType = 'approve' | 'stake' | 'unstake' | 'withdraw-dividends' | 'distribute-pool';

type Payload = {
    amount: string;
};

type Operation = {
    txId?: string;
    type?: OperationType;
    payload?: Payload;
};

const SECOND = 1000;
const REFETCH_INTERVAL = 60 * SECOND;

export const StakingPageContainer = ({ as: Component = StakingPage, ...other }: Props) => {
    const {
        isConnectedMetaMask,
        isCorrectChainId,
        onError,
        onCheckAllowance,
        onApprove,
        onStakeTokens,
        onUnstakeTokens,
        onWithdrawDividends,
        onDistributePool
    } = useMetaMask();
    const addToast = useToast();
    const { t } = useTranslation();

    const [isOpenStakingModal, setIsOpenStakingModal] = useState(false);
    const [isStaking, setIsStaking] = useState(false);
    const [operation, setOperation] = useState<Operation>({});

    const successMessages = {
        stake: t('Вы успешно застекировали токены!'),
        unstake: t('Вы успешно вывели LP-токен+прибыль!'),
        'withdraw-dividends': t('Вы успешно вывели прибыль!'),
        'distribute-pool': t('Вы успешно пополнили пул!')
    };

    const failMessages = {
        approve: t('Вам не удалось выполнить approve!'),
        stake: t('Вам не удалось застекировать токены!'),
        unstake: t('Вам не удалось вывести LP-токен+прибыль!'),
        'withdraw-dividends': t('Вам не удалось вывести прибыль!'),
        'distribute-pool': t('Вам не удалось пополнить пул!')
    };

    const pendingTransactionMessage = t('Ваша транзакция находится в процессе обработки');
    const unknownErrorMessage = t('Произошла неизвестная ошибка. Пожалуйста, попробуйте позже');

    const handleError = (e: Error) => {
        console.log('[e]', e);
        addToast({ type: 'error', message: unknownErrorMessage });
    };

    const {
        balances,
        isUpdating: isUpdatingBalances,
        onUpdate: onUpdateBalances
    } = useBalances({ interval: REFETCH_INTERVAL, onError: handleError });

    const {
        isDisabled: isDisabledPoolDistribution,
        isUpdating: isUpdatingPoolDistributionStatus,
        onUpdate: onUpdatePoolDistributionStatus
    } = usePoolDistribution({ interval: REFETCH_INTERVAL, onError: handleError });

    const handleSuccessfulTransaction = async (type: OperationType, payload: Payload) => {
        if (type === 'approve') {
            try {
                const { amount } = payload;

                const txId = await onStakeTokens(amount);

                setOperation({ txId, type: 'stake' });
            } catch (e) {
                setIsStaking(false);

                onError(e as MetaMaskError, () => {
                    console.log('[e]', e);
                    addToast({ type: 'error', message: unknownErrorMessage });
                });
            }
        } else {
            const message = successMessages[type];

            addToast({ type: 'success', message });

            switch (type) {
                case 'distribute-pool':
                    onUpdatePoolDistributionStatus();
                    onUpdateBalances();
                    break;

                case 'stake':
                    setIsStaking(false);
                    setIsOpenStakingModal(false);
                    onUpdateBalances();
                    break;

                default:
                    onUpdateBalances();
            }
        }
    };

    const handleFailedTransaction = (type: OperationType) => {
        const message = failMessages[type];

        addToast({ type: 'error', message });

        if (type === 'stake') setIsStaking(false);
    };

    const handlePendingTransaction = (type: OperationType) => {
        addToast({ type: 'error', message: pendingTransactionMessage });

        if (type === 'stake') setIsStaking(false);
    };

    const { isPolling } = usePollTransactionStatus(operation.txId, {
        onSuccess: async status => {
            const { type, payload } = operation;

            switch (status) {
                case 'success':
                    return handleSuccessfulTransaction(type!, payload!);

                case 'fail':
                    return handleFailedTransaction(type!);

                case 'pending':
                    return handlePendingTransaction(type!);
            }
        },
        onError: e => {
            if (operation.type === 'stake') {
                setIsStaking(false);
            }

            console.log('[e]', e);
        }
    });

    const { dividends, stakedTokens, areFrozenTokens } = balances;

    const isPollingUnstaking = isPolling && operation.type === 'unstake';
    const isPollingDividendsWithdrawal = isPolling && operation.type === 'withdraw-dividends';
    const isDisabledStaking = !isConnectedMetaMask || !isCorrectChainId;
    const isDisabledUnstaking =
        !isConnectedMetaMask ||
        !isCorrectChainId ||
        areFrozenTokens ||
        +stakedTokens.raw <= 0 ||
        isPollingUnstaking;
    const isDisabledDividendsWithdrawal =
        !isConnectedMetaMask ||
        !isCorrectChainId ||
        +dividends.raw <= 0 ||
        isPollingDividendsWithdrawal;

    const handleToggleStakingModal = () => {
        setIsOpenStakingModal(prevIsOpen => !prevIsOpen);
    };

    const handleStakeTokens = async (amount: string) => {
        if (isDisabledStaking) return;

        const fixedAmount = convertNumberToUint256(amount);

        setIsStaking(true);

        try {
            const { isApproved, value } = await onCheckAllowance(fixedAmount);

            if (isApproved) {
                const txId = await onStakeTokens(fixedAmount);

                return setOperation({ txId, type: 'stake' });
            }

            // TODO: Change a typo, so there won't be a need to add a !
            const txId = await onApprove(value!);

            const payload = { amount: value! };

            setOperation({ txId, type: 'approve', payload });
        } catch (e) {
            setIsStaking(false);

            onError(e as MetaMaskError, () => {
                console.log('[e]', e);
                addToast({ type: 'error', message: unknownErrorMessage });
            });
        }
    };

    const handleUnstakeTokens = async () => {
        if (isDisabledUnstaking) return;

        try {
            const txId = await onUnstakeTokens(stakedTokens.raw);

            setOperation({ txId, type: 'unstake' });
        } catch (e) {
            onError(e as MetaMaskError, () => {
                console.log('[e]', e);
                addToast({ type: 'error', message: unknownErrorMessage });
            });
        }
    };

    const handleWithdrawDividends = async () => {
        if (isDisabledDividendsWithdrawal) return;

        try {
            const txId = await onWithdrawDividends();

            setOperation({ txId, type: 'withdraw-dividends' });
        } catch (e) {
            onError(e as MetaMaskError, () => {
                console.log('[e]', e);
                addToast({ type: 'error', message: unknownErrorMessage });
            });
        }
    };

    const handleDistributePool = async () => {
        if (isDisabledPoolDistribution) return;

        try {
            const txId = await onDistributePool();

            setOperation({ txId, type: 'distribute-pool' });
        } catch (e) {
            onError(e as MetaMaskError, () => {
                console.log('[e]', e);
                addToast({ type: 'error', message: unknownErrorMessage });
            });
        }
    };

    return (
        <Component
            {...other}
            balances={balances}
            isOpenStakingModal={isOpenStakingModal}
            isStaking={isStaking}
            isUpdatingBalances={isUpdatingBalances}
            isUpdatingPoolDistributionStatus={isUpdatingPoolDistributionStatus}
            isDisabledStaking={isDisabledStaking}
            isDisabledUnstaking={isDisabledUnstaking}
            isDisabledDividendsWithdrawal={isDisabledDividendsWithdrawal}
            isDisabledPoolDistribution={isDisabledPoolDistribution}
            onToggleStakingModal={handleToggleStakingModal}
            onStakeTokens={handleStakeTokens}
            onUnstakeTokens={handleUnstakeTokens}
            onWithdrawDividends={handleWithdrawDividends}
            onDistributePool={handleDistributePool}
        />
    );
};
