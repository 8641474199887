import styled from 'styled-components/macro';

import { backgroundImageMixin } from '../../../utils';
import errorTriangle from '../../../resources/images/red-error-triangle.svg';

const size = '2rem';

export const StyledErrorIcon = styled.div`
    width: ${size};
    height: ${size};
    position: absolute;
    top: calc(5rem / 2 - 1rem);
    right: 1.8rem;
    pointer-events: none;

    ${backgroundImageMixin({ url: errorTriangle, size })}
`;
