import { useEffect, useState } from 'react';
import { noop } from 'lodash';

import { useMetaMask } from './useMetaMask';

type StatusType = 'success' | 'fail' | 'pending';

type Options = {
    interval?: number;
    maxAttempts?: number;
    onSuccess?: (status: StatusType) => void;
    onError?: (error: Error) => void;
};

const TRANSACTION_PENDING = 'pending';
const TRANSACTION_FAILED = 'fail';
const TRANSACTION_SUCCEEDED = 'success';
const STATUS_VALUES = { '0x0': TRANSACTION_FAILED, '0x1': TRANSACTION_SUCCEEDED };
const HALF_SECOND = 500;
const MAX_ATTEMPTS = 30;
const NO_ATTEMPTS = 0;

/**
 * @description
 * Get transaction status through multiple async requests
 */
export const usePollTransactionStatus = (txId?: string, options: Options = {}) => {
    const {
        interval = HALF_SECOND,
        maxAttempts = MAX_ATTEMPTS,
        onSuccess = noop,
        onError = noop
    } = options;

    const [status, setStatus] = useState<StatusType | null>(null);
    const [attempts, setAttempts] = useState(NO_ATTEMPTS);
    const { ethereum, isInstalledMetaMask, onGetTransactionReceipt } = useMetaMask();

    useEffect(() => {
        if (!isInstalledMetaMask || !txId) return;

        setAttempts(prevAttempts => prevAttempts + 1);
    }, [txId, isInstalledMetaMask]);

    useEffect(() => {
        if (!txId) return;
        if (!attempts) return;

        if (attempts === maxAttempts) {
            setAttempts(NO_ATTEMPTS);
            setStatus(TRANSACTION_PENDING);
            return onSuccess(TRANSACTION_PENDING);
        }

        setTimeout(async () => {
            try {
                const result = await onGetTransactionReceipt(txId);

                if (!result) return setAttempts(prevAttempts => prevAttempts + 1);

                const { status: txStatus } = result;
                const transactionStatus = STATUS_VALUES[txStatus] as StatusType;

                setStatus(transactionStatus);
                setAttempts(NO_ATTEMPTS);
                onSuccess(transactionStatus);
            } catch (e) {
                setAttempts(NO_ATTEMPTS);
                onError(e);
                console.log('[e]', e);
            }
        }, interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attempts, ethereum, txId, interval, maxAttempts]);

    return { status, isPolling: !!attempts };
};
