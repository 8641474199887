import { createGlobalStyle } from 'styled-components/macro';

import { ThemedPropsType } from '../theme';

const fontFamily = ({ theme }: ThemedPropsType) => theme.fontFamily.default;
const fontSize = ({ theme }: ThemedPropsType) => theme.fontSize.default;
const textColor = ({ theme }: ThemedPropsType) => theme.color.default;

export const GlobalReset = createGlobalStyle`
    *,
    *::before,
    *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%;
        height: 100%;
    }

    body {
        font-family: ${fontFamily};
        font-size: ${fontSize};
        font-weight: 500;
        color: ${textColor};
        height: 100%;
        overflow-x: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    ul {
        list-style-type: none;
    }

    img {
        display: block;
        width: 100%;
    }

    a {
        color: inherit;
        text-decoration: underline transparent;
    }

    #root {
        height: 100%;
    }
`;
