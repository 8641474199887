import styled, { css } from 'styled-components/macro';

import { StyledText } from './StyledText';
import { ExpandedThemedPropsType } from '../theme';

type Props = ExpandedThemedPropsType<{
    required?: boolean;
}>;

const orangeClr = ({ theme }: Props) => theme.color.primary[500];

const applyRequired = ({ required }: Props) => {
    if (!required) return;

    return css`
        &::after {
            content: ' *';
            color: ${orangeClr};
        }
    `;
};

export const StyledLabel = styled(StyledText).attrs({ as: 'label', size: 'md', bold: true })`
    ${applyRequired}
`;
