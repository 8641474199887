import styled from 'styled-components/macro';

import { StyledBox, StyledButton } from '../../../styles';
import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledWalletBox = styled(StyledBox).attrs({ size: 'md', bg: 'primary' })`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.2rem;

    ${StyledButton} {
        width: 100%;

        @media (min-width: ${lg}) {
            width: auto;
        }
    }
`;
