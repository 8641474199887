import styled, { css } from 'styled-components/macro';

import { ExpandedThemedPropsType } from '../theme';

type Props = ExpandedThemedPropsType<{
    size: 'md' | 'lg';
    bg: 'primary' | 'secondary';
}>;

const gradient = ({ theme }: Props) => theme.gradient.tertiary;
const borderRadius = ({ theme }: Props) => theme.borderRadius.lg;
const lg = ({ theme }: Props) => theme.media.lg;

// STYLING:
const refinedBlueClr = '#A8BBFF0A';

const applySize = ({ size }: Props) => {
    let padding;
    let paddingLg;

    switch (size) {
        case 'md':
            padding = '2rem';
            paddingLg = '3.2rem';
            break;

        case 'lg':
            padding = '2rem';
            paddingLg = '4.8rem';
            break;
    }

    return css`
        padding: ${padding};

        @media (min-width: ${lg}) {
            padding: ${paddingLg};
        }
    `;
};

const applyBackground = ({ bg }: Props) => {
    switch (bg) {
        case 'primary':
            return css`
                background-image: ${gradient};
            `;

        case 'secondary':
            return css`
                background-color: ${refinedBlueClr};
            `;
    }
};

export const StyledBox = styled.div<Props>`
    border-radius: ${borderRadius};

    ${applySize}
    ${applyBackground}
`;
