import { useState, useEffect, useCallback } from 'react';
import { noop } from 'lodash';

import { useMetaMask } from './useMetaMask';

// TODO: Move this to common types
type TokensValue = {
    /**
     * Raw value is used for calculations
     */
    raw: string;
    /**
     * Rounded value is used for displaying a value to the user
     */
    rounded: string;
};

export type Balances = {
    // User balances
    lpTokens: TokensValue;
    usdtTokens: TokensValue;
    drsTokens: TokensValue;
    dividends: TokensValue;
    stakedTokens: TokensValue;
    // Overall stats
    totalStakedTokens: TokensValue;
    totalDividends: TokensValue;
    darsRate: TokensValue;
    // Staking info
    areFrozenTokens: boolean;
    freezingPeriod: number;
    freezeDate: number;
};

type BalancesOptions = {
    interval?: number;
    onSuccess?: (balances: Balances) => void;
    onError?: (e: Error) => void;
};

const {
    REACT_APP_LP_TOKEN_CONTRACT_ADDRESS: lpTokenContractAddress,
    REACT_APP_USDT_CONTRACT_ADDRESS: usdtContractAddress,
    REACT_APP_DARS_TOKEN_CONTRACT_ADDRESS: darsTokenContractAddress
} = process.env;

const INITIAL_BALANCES = {
    lpTokens: { raw: '0', rounded: '0.000000' },
    usdtTokens: { raw: '0', rounded: '0.000000' },
    drsTokens: { raw: '0', rounded: '0.000000' },
    dividends: { raw: '0', rounded: '0.000000' },
    stakedTokens: { raw: '0', rounded: '0.000000' },
    totalStakedTokens: { raw: '0', rounded: '0.00' },
    totalDividends: { raw: '0', rounded: '0.00' },
    darsRate: { raw: '0', rounded: '0.000000' },
    areFrozenTokens: true,
    freezingPeriod: 0,
    freezeDate: 0
};

export const useBalances = (options: BalancesOptions = {}) => {
    const { interval, onSuccess = noop, onError = noop } = options;

    const {
        account,
        isCorrectChainId,
        onGetBalance,
        onGetUserInfo,
        onGetPoolInfo,
        onGetDarsRate,
        onGetDividends,
        onCheckUnstakeStatus
    } = useMetaMask();

    const [count, setCount] = useState(0);
    const [isUpdating, setIsUpdating] = useState(false);
    const [error, setError] = useState<Error>();
    const [balances, setBalances] = useState<Balances>(INITIAL_BALANCES);

    useEffect(() => {
        if (!account || !isCorrectChainId) return;

        (async () => {
            try {
                setIsUpdating(true);

                const lpTokenOptions = { from: account, to: lpTokenContractAddress! };
                const usdtOptions = { from: account, to: usdtContractAddress! };
                const darsTokenOptions = { from: account, to: darsTokenContractAddress! };

                const lpTokens = await onGetBalance(lpTokenOptions);
                const usdtTokens = await onGetBalance(usdtOptions);
                const drsTokens = await onGetBalance(darsTokenOptions);
                const dividends = await onGetDividends();
                const userInfo = await onGetUserInfo();
                const poolInfo = await onGetPoolInfo();
                const darsRate = await onGetDarsRate();
                const areFrozenTokens = await onCheckUnstakeStatus();

                const nextBalances = {
                    ...userInfo,
                    ...poolInfo,
                    darsRate,
                    lpTokens,
                    usdtTokens,
                    drsTokens,
                    dividends,
                    areFrozenTokens
                };

                setBalances(nextBalances);
                onSuccess(nextBalances);
            } catch (e) {
                setError(e as Error);
                onError(e);
            } finally {
                setIsUpdating(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        count,
        account,
        isCorrectChainId,
        onGetBalance,
        onGetUserInfo,
        onGetDividends,
        onCheckUnstakeStatus,
        onGetDarsRate,
        onGetPoolInfo
    ]);

    // Refresh the balance at a specific time interval
    useEffect(() => {
        if (!interval || !isCorrectChainId) return;

        const timeout = setTimeout(() => {
            setCount(prevCount => prevCount + 1);
        }, interval);

        return () => clearTimeout(timeout);
    }, [count, interval, isCorrectChainId]);

    const handleUpdateBalances = useCallback(() => setCount(prevCount => prevCount + 1), []);

    return {
        balances,
        error,
        isUpdating,
        onUpdate: handleUpdateBalances
    };
};
