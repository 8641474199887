import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledStatsItem = styled.li`
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        min-width: 12rem;
        margin-bottom: 2.5rem;

        @media (min-width: ${lg}) {
            margin-bottom: 0;
            margin-right: 5rem;
        }
    }
`;
