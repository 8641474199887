import { ReactElement, ReactNode, JSXElementConstructor } from 'react';
import ReactTooltip from 'rc-tooltip';
import { isObject } from 'lodash';

import { StyledPopupContent } from './styles';

const getPortalNode = (portalNode?: HTMLElement) => () =>
    portalNode || document.getElementById('portal-root')!;

const DEFAULT_OFFSET = { offset: [0, 0] };

type Trigger = 'click' | 'hover' | 'focus';

type Props = {
    /**
     * Indicates which actions cause tooltip shown
     * @default 'hover'
     */
    trigger?: Trigger | Trigger[];
    /**
     * Indicates which side tooltip should appear
     * @default 'top'
     */
    placement?:
        | 'left'
        | 'right'
        | 'top'
        | 'bottom'
        | 'topLeft'
        | 'topRight'
        | 'bottomLeft'
        | 'bottomRight';
    /**
     * Represents tooltip content
     */
    content: ReactNode;
    /**
     * Indicates animation on mounting
     */
    animation?: string;
    /**
     * Sets delay before appearing
     * @default 0
     */
    mouseEnterDelay?: number;
    /**
     * Sets delay before disappearing
     * @default 0.1
     */
    mouseLeaveDelay?: number;
    /**
     * Indicates whether tooltip is visible initially
     * @default false
     */
    defaultVisible?: boolean;
    /**
     * Align tooltip in Y
     * @default 0
     */
    offsetY?: number;
    /**
     * Indicates html node which will act as
     * tooltip container. By default the tooltip attaches
     * to the body. If you want to change the container,
     * simply set a new element.
     * @default document.getElementById('portal-root')
     */
    portalNode?: HTMLElement;
    /**
     * Element, which should have a tooltip
     */
    children: ReactElement<any, string | JSXElementConstructor<any>>;
};

export const Tooltip = ({
    trigger = 'hover',
    placement = 'top',
    content,
    mouseEnterDelay = 0,
    mouseLeaveDelay = 0.1,
    defaultVisible = false,
    animation,
    portalNode,
    offsetY,
    children
}: Props) => (
    <ReactTooltip
        trigger={trigger}
        placement={placement}
        overlay={isObject(content) ? content : <StyledPopupContent>{content}</StyledPopupContent>}
        mouseEnterDelay={mouseEnterDelay}
        mouseLeaveDelay={mouseLeaveDelay}
        defaultVisible={defaultVisible}
        // TODO: Handle bug or search for other animation: dark vertical lines after animation
        animation={animation && 'zoom'}
        getTooltipContainer={getPortalNode(portalNode)}
        align={offsetY ? { offset: [0, offsetY] } : DEFAULT_OFFSET}
    >
        {children}
    </ReactTooltip>
);
