import styled from 'styled-components/macro';
import { ToastContainer } from 'react-toastify';

import { ThemedPropsType } from '../../../theme';

const borderRadius = ({ theme }: ThemedPropsType) => theme.borderRadius.xs;

// TODO: This is a default `react-toastify` media size.
// It should be changed on appropriate
const mediaSize = '480px';

export const StyledToaster = styled(ToastContainer)`
    transform: translate3d(0, 0, 999.9rem);
    position: fixed;
    z-index: 2;

    @media only screen and (max-width: ${mediaSize}) {
        width: calc(100% - 2rem);
        margin: 1rem;
        left: 0;
    }

    .Toastify__toast {
        margin-bottom: 1.5rem;
        overflow: hidden;
        position: relative;
        direction: ltr;

        &:last-child {
            margin-bottom: 0;
        }

        @media only screen and (max-width: ${mediaSize}) {
            margin-bottom: 1rem;
        }
    }

    .Toastify__progress-bar {
        width: 6.5rem;
        border-radius: 0 0 0 ${borderRadius};
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        transform-origin: bottom;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &.Toastify__toast-container--top-left {
        top: 1em;
        left: 1em;

        @media only screen and (max-width: ${mediaSize}) {
            left: 0;
        }
    }

    &.Toastify__toast-container--top-center {
        top: 1em;
        left: 50%;
        transform: translateX(-50%);
    }

    &.Toastify__toast-container--top-right {
        top: 1em;
        right: 1em;
    }

    &.Toastify__toast-container--bottom-left {
        bottom: 1em;
        left: 1em;
    }

    &.Toastify__toast-container--bottom-center {
        bottom: 1em;
        left: 50%;
        transform: translateX(-50%);
    }

    &.Toastify__toast-container--bottom-right {
        bottom: 1em;
        right: 1em;
    }

    @media only screen and (max-width: ${mediaSize}) {
        &.Toastify__toast-container--top-left,
        &.Toastify__toast-container--top-center,
        &.Toastify__toast-container--top-right {
            top: 0;
            transform: translateX(0);
        }

        &.Toastify__toast-container--bottom-left,
        &.Toastify__toast-container--bottom-center,
        &.Toastify__toast-container--bottom-right {
            bottom: 0;
            transform: translateX(0);
        }

        &.Toastify__toast-container--rtl {
            right: 0;
            left: initial;
        }
    }

    .Toastify__toast--rtl {
        direction: rtl;
    }

    @keyframes Toastify__trackProgress {
        0% {
            transform: scaleY(1);
        }
        100% {
            transform: scaleY(0);
        }
    }

    .Toastify__progress-bar--animated {
        animation: Toastify__trackProgress linear 1 forwards;
    }

    .Toastify__progress-bar--controlled {
        transition: transform 200ms;
    }

    .Toastify__progress-bar--rtl {
        right: 0;
        left: initial;
        transform-origin: right;
    }

    @keyframes Toastify__bounceInRight {
        from,
        60%,
        75%,
        90%,
        to {
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        from {
            opacity: 0;
            transform: translate3d(300rem, 0, 0);
        }
        60% {
            opacity: 1;
            transform: translate3d(-2.5rem, 0, 0);
        }
        75% {
            transform: translate3d(1rem, 0, 0);
        }
        90% {
            transform: translate3d(-0.5rem, 0, 0);
        }
        to {
            transform: none;
        }
    }

    @keyframes Toastify__bounceOutRight {
        20% {
            opacity: 1;
            transform: translate3d(-2rem, 0, 0);
        }
        to {
            opacity: 0;
            transform: translate3d(200rem, 0, 0);
        }
    }

    @keyframes Toastify__bounceInLeft {
        from,
        60%,
        75%,
        90%,
        to {
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        0% {
            opacity: 0;
            transform: translate3d(-300rem, 0, 0);
        }
        60% {
            opacity: 1;
            transform: translate3d(2.5rem, 0, 0);
        }
        75% {
            transform: translate3d(-1rem, 0, 0);
        }
        90% {
            transform: translate3d(0.5rem, 0, 0);
        }
        to {
            transform: none;
        }
    }

    @keyframes Toastify__bounceOutLeft {
        20% {
            opacity: 1;
            transform: translate3d(2rem, 0, 0);
        }
        to {
            opacity: 0;
            transform: translate3d(-200rem, 0, 0);
        }
    }

    @keyframes Toastify__bounceInUp {
        from,
        60%,
        75%,
        90%,
        to {
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        from {
            opacity: 0;
            transform: translate3d(0, 300rem, 0);
        }
        60% {
            opacity: 1;
            transform: translate3d(0, -2rem, 0);
        }
        75% {
            transform: translate3d(0, 1rem, 0);
        }
        90% {
            transform: translate3d(0, -0.5rem, 0);
        }
        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes Toastify__bounceOutUp {
        20% {
            transform: translate3d(0, -1rem, 0);
        }
        40%,
        45% {
            opacity: 1;
            transform: translate3d(0, 2rem, 0);
        }
        to {
            opacity: 0;
            transform: translate3d(0, -200rem, 0);
        }
    }

    @keyframes Toastify__bounceInDown {
        from,
        60%,
        75%,
        90%,
        to {
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        0% {
            opacity: 0;
            transform: translate3d(0, -300rem, 0);
        }
        60% {
            opacity: 1;
            transform: translate3d(0, 2.5rem, 0);
        }
        75% {
            transform: translate3d(0, -1rem, 0);
        }
        90% {
            transform: translate3d(0, 0.5rem, 0);
        }
        to {
            transform: none;
        }
    }

    @keyframes Toastify__bounceOutDown {
        20% {
            transform: translate3d(0, 1rem, 0);
        }
        40%,
        45% {
            opacity: 1;
            transform: translate3d(0, -2rem, 0);
        }
        to {
            opacity: 0;
            transform: translate3d(0, 200rem, 0);
        }
    }

    .Toastify__bounce-enter--top-left,
    .Toastify__bounce-enter--bottom-left {
        animation-name: Toastify__bounceInLeft;
    }

    .Toastify__bounce-enter--top-right,
    .Toastify__bounce-enter--bottom-right {
        animation-name: Toastify__bounceInRight;
    }

    .Toastify__bounce-enter--top-center {
        animation-name: Toastify__bounceInDown;
    }

    .Toastify__bounce-enter--bottom-center {
        animation-name: Toastify__bounceInUp;
    }

    .Toastify__bounce-exit--top-left,
    .Toastify__bounce-exit--bottom-left {
        animation-name: Toastify__bounceOutLeft;
    }

    .Toastify__bounce-exit--top-right,
    .Toastify__bounce-exit--bottom-right {
        animation-name: Toastify__bounceOutRight;
    }

    .Toastify__bounce-exit--top-center {
        animation-name: Toastify__bounceOutUp;
    }

    .Toastify__bounce-exit--bottom-center {
        animation-name: Toastify__bounceOutDown;
    }

    @keyframes Toastify__zoomIn {
        from {
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }
        50% {
            opacity: 1;
        }
    }

    @keyframes Toastify__zoomOut {
        from {
            opacity: 1;
        }
        50% {
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }
        to {
            opacity: 0;
        }
    }

    .Toastify__zoom-enter {
        animation-name: Toastify__zoomIn;
    }

    .Toastify__zoom-exit {
        animation-name: Toastify__zoomOut;
    }

    @keyframes Toastify__flipIn {
        from {
            transform: perspective(40rem) rotate3d(1, 0, 0, 90deg);
            animation-timing-function: ease-in;
            opacity: 0;
        }
        40% {
            transform: perspective(40rem) rotate3d(1, 0, 0, -20deg);
            animation-timing-function: ease-in;
        }
        60% {
            transform: perspective(40rem) rotate3d(1, 0, 0, 10deg);
            opacity: 1;
        }
        80% {
            transform: perspective(40rem) rotate3d(1, 0, 0, -5deg);
        }
        to {
            transform: perspective(40rem);
        }
    }

    @keyframes Toastify__flipOut {
        from {
            transform: perspective(40rem);
        }
        30% {
            transform: perspective(40rem) rotate3d(1, 0, 0, -20deg);
            opacity: 1;
        }
        to {
            transform: perspective(40rem) rotate3d(1, 0, 0, 90deg);
            opacity: 0;
        }
    }

    .Toastify__flip-enter {
        animation-name: Toastify__flipIn;
    }

    .Toastify__flip-exit {
        animation-name: Toastify__flipOut;
    }

    @keyframes Toastify__slideInRight {
        from {
            transform: translate3d(110%, 0, 0);
            visibility: visible;
        }
        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes Toastify__slideInLeft {
        from {
            transform: translate3d(-110%, 0, 0);
            visibility: visible;
        }
        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes Toastify__slideInUp {
        from {
            transform: translate3d(0, 110%, 0);
            visibility: visible;
        }
        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes Toastify__slideInDown {
        from {
            transform: translate3d(0, -110%, 0);
            visibility: visible;
        }
        to {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes Toastify__slideOutRight {
        from {
            transform: translate3d(0, 0, 0);
        }
        to {
            visibility: hidden;
            transform: translate3d(110%, 0, 0);
        }
    }

    @keyframes Toastify__slideOutLeft {
        from {
            transform: translate3d(0, 0, 0);
        }
        to {
            visibility: hidden;
            transform: translate3d(-110%, 0, 0);
        }
    }

    @keyframes Toastify__slideOutDown {
        from {
            transform: translate3d(0, 0, 0);
        }
        to {
            visibility: hidden;
            transform: translate3d(0, 50rem, 0);
        }
    }

    @keyframes Toastify__slideOutUp {
        from {
            transform: translate3d(0, 0, 0);
        }
        to {
            visibility: hidden;
            transform: translate3d(0, -50rem, 0);
        }
    }

    .Toastify__slide-enter--top-left,
    .Toastify__slide-enter--bottom-left {
        animation-name: Toastify__slideInLeft;
    }

    .Toastify__slide-enter--top-right,
    .Toastify__slide-enter--bottom-right {
        animation-name: Toastify__slideInRight;
    }

    .Toastify__slide-enter--top-center {
        animation-name: Toastify__slideInDown;
    }

    .Toastify__slide-enter--bottom-center {
        animation-name: Toastify__slideInUp;
    }

    .Toastify__slide-exit--top-left,
    .Toastify__slide-exit--bottom-left {
        animation-name: Toastify__slideOutLeft;
    }

    .Toastify__slide-exit--top-right,
    .Toastify__slide-exit--bottom-right {
        animation-name: Toastify__slideOutRight;
    }

    .Toastify__slide-exit--top-center {
        animation-name: Toastify__slideOutUp;
    }

    .Toastify__slide-exit--bottom-center {
        animation-name: Toastify__slideOutDown;
    }
`;
