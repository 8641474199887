import styled, { css } from 'styled-components/macro';

import { ExpandedThemedPropsType } from '../../../theme';
import { ModalSize } from '../Modal';

type Props = ExpandedThemedPropsType<{
    size?: ModalSize;
    isAppearing?: boolean;
    isHiding?: boolean;
}>;

const DURATION = 300;

const fontFamily = ({ theme }: Props) => theme.fontFamily.primary;
const fontSize = ({ theme }: Props) => theme.fontSize.md;
const borderRadius = ({ theme }: Props) => theme.borderRadius.lg;
const textColor = ({ theme }: Props) => theme.color.default;
const lg = ({ theme }: Props) => theme.media.lg;

const maxWidth = ({ size }: Props) => {
    switch (size) {
        case 'sm':
            return '25rem';

        case 'md':
        default:
            return '50rem';

        case 'lg':
            return '75rem';

        case 'xl':
            return '120rem';
    }
};

const applyAnimation = ({ isAppearing, isHiding }: Props) => {
    if (!isAppearing && !isHiding) return;

    let scale;
    let opacity;

    switch (true) {
        case isAppearing:
            scale = '1';
            opacity = '1';
            break;

        case isHiding:
            scale = '0.96';
            opacity = '0';
            break;

        default:
            return;
    }

    return css`
        transition: transform ${DURATION}ms ease, opacity ${DURATION}ms ease;
        transform: scale(${scale});
        opacity: ${opacity};
    `;
};

export const StyledModal = styled.div<Props>`
    max-width: ${maxWidth};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // STYLING:
    background-image: linear-gradient(188.06deg, #191d22 10.51%, #4d5465 151.97%);
    font-family: ${fontFamily};
    font-size: ${fontSize};
    font-weight: 500;
    color: ${textColor};
    position: relative;
    z-index: 2;

    ${applyAnimation}

    @media (min-width: ${lg}) {
        height: auto;
        border-radius: ${borderRadius};
    }
`;
