import styled, { keyframes } from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

// STYLING:
const size = '9.4rem';
const grayClr = '#2a2d39';

const pulse = ({ theme }: ThemedPropsType) => {
    const orangeClr = theme.color.primary[500];

    return keyframes`
        0% {
            box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent;
        }

        100% {
            box-shadow: 0 0 0 0.5rem ${grayClr}, 0 0 0.1rem 0.8rem ${orangeClr};
        }
    `;
};

export const StyledCurrencyLogo = styled.img`
    width: ${size};
    height: ${size};
    flex-shrink: 0;
    margin-right: 2.4rem;
    border-radius: 50%;
    animation: ${pulse} 1500ms infinite alternate;
`;
