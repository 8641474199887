import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';

import { App, TranslationProvider, MetaMaskProvider, ToastProvider } from './components';
import { GlobalReset, GlobalUtility, GlobalTooltips } from './styles';
import { theme } from './theme';
import './styles/fonts.css';

export const Root = () => (
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <TranslationProvider>
                <MetaMaskProvider>
                    <GlobalReset />
                    <GlobalUtility />
                    <GlobalTooltips />
                    <ToastProvider />

                    <App />
                    <div id="portal-root" />
                </MetaMaskProvider>
            </TranslationProvider>
        </ThemeProvider>
    </BrowserRouter>
);
