import styled from 'styled-components/macro';

import { rgba } from '../../../utils';
import { ThemedPropsType } from '../../../theme';

const whiteClr = ({ theme }: ThemedPropsType) => theme.color.light[100];
const blackClr = ({ theme }: ThemedPropsType) => theme.color.dark[900];
const refinedBlackClr = ({ theme }: ThemedPropsType) => rgba(theme.color.dark[900], 0.5);
const borderRadius = ({ theme }: ThemedPropsType) => theme.borderRadius.sm;

export const StyledPopupContent = styled.div`
    max-width: 42rem;
    border-radius: ${borderRadius};
    padding: 2rem;
    box-shadow: 0.1rem 0.2rem 0.6rem ${refinedBlackClr};
    background-color: ${whiteClr};
    color: ${blackClr};
`;
