import styled from 'styled-components/macro';

import { StyledHeading, StyledQuestionIcon } from '../../../styles';

export const StyledSubtitle = styled(StyledHeading).attrs({ as: 'h2', size: 'md' })`
    display: inline-flex;
    align-items: center;
    margin-bottom: 2.5rem;

    ${StyledQuestionIcon} {
        margin-left: 1rem;
    }
`;
