import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const whiteClr = ({ theme }: ThemedPropsType) => theme.color.light[100];
const borderRadius = ({ theme }: ThemedPropsType) => theme.borderRadius.xs;
const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledToast = styled.div`
    display: flex;
    max-height: 80rem;
    background-color: ${whiteClr};
    border-radius: ${borderRadius};

    @media (min-width: ${lg}) {
        width: 42rem;
    }
`;
