import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { Toast, ToastType } from '../components';

type ToastOptions = {
    type: ToastType;
    message: string;
};

export const useToast = () =>
    useCallback(
        ({ type, message }: ToastOptions) =>
            toast(props => (
                <Toast {...props} type={type}>
                    {message}
                </Toast>
            )),
        []
    );
