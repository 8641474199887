import { useState, useEffect, useMemo, useCallback, ReactNode } from 'react';
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import { isEmpty } from 'lodash';

import { MetaMaskContext } from '../../contexts';
import { getRoundedValue } from '../../utils';
import {
    metamaskInterfaces,
    metamaskChainsParams,
    MetaMaskChainParams,
    ValidChainId
} from '../../constants';

type Props = {
    children: ReactNode;
};

type Param = {
    from: string;
    to: string;
    data: string;
};

type Params = (Param | MetaMaskChainParams | string)[];

type DecodedParameters<T> = {
    [key: string]: T;
};

type Method =
    | 'eth_sendTransaction'
    | 'eth_call'
    | 'eth_requestAccounts'
    | 'eth_getTransactionReceipt'
    | 'wallet_addEthereumChain';

export type TransactionReceiptResponse = {
    status: '0x0' | '0x1';
};
export type GetBalanceOptions = {
    from: string;
    to: string;
};

export type EthereumProvider = {
    on: (event: 'chainChanged', callback: () => void) => void;
    request: (body: { method: Method; params?: Params }) => Promise<any>;
};

export type MetaMaskError = Error & {
    code: number;
};

const {
    REACT_APP_DARS_STAKE_CONTRACT_ADDRESS: darsStakeContractAddress,
    REACT_APP_LP_TOKEN_CONTRACT_ADDRESS: lpTokenContractAddress,
    REACT_APP_USDT_CONTRACT_ADDRESS: usdtContractAddress,
    REACT_APP_DARS_BASIS_CONTRACT_ADDRESS: darsBasisContractAddress,
    REACT_APP_CHAIN_ID
} = process.env;

const SECOND = 1000;
const REJECT_SIGN_CODE = 4001;
const ALREADY_PROCESSING_METAMASK_CODE = -32002;

const web3 = new Web3(Web3.givenProvider || 'ws://localhost:8545');

const {
    APPROVE_METHOD_INTERFACE,
    ALLOWANCE_METHOD_INTERFACE,
    BALANCE_METHOD_INTERFACE,
    STAKE_METHOD_INTERFACE,
    UNSTAKE_METHOD_INTERFACE,
    USER_INFO_METHOD_INTERFACE,
    GET_DIVIDENDS_METHOD_INTERFACE,
    CHECK_UNSTAKE_STATUS_METHOD_INTERFACE,
    POOL_INFO_METHOD_INTERFACE,
    WITHDRAW_DIVIDENDS_METHOD_INTERFACE,
    DISTRIBUTE_POOL_METHOD_INTERFACE,
    MINIMUM_DISTRIBUTE_BALANCE_METHOD_INTERFACE,
    GET_DARS_RATE_METHOD_INTERFACE
} = metamaskInterfaces;

const requiredChainId = +REACT_APP_CHAIN_ID! as ValidChainId;

/**
 * @description
 * Get function call encoded to string
 *
 * @returns function call encoded to string
 */
const getEncodedFunctionCall = (functionInterface: AbiItem, attributes: string[]) =>
    web3.eth.abi.encodeFunctionCall(functionInterface, attributes);

/**
 * @description
 * Get allowance result based upon comparison of
 * transaction value and value allowed by smart-contract
 *
 * @returns approvement status and value to approve
 */
const getApprovementStatusAndValue = (transactionValue: string, allowedValue: string) => {
    const transactionValueBN = web3.utils.toBN(transactionValue);
    const allowedValueBN = web3.utils.toBN(allowedValue);

    // Transaction is already approved if `allowedValue` >= `transactionValue`
    const isApproved = transactionValueBN.cmp(allowedValueBN) < 1;

    return { isApproved, value: isApproved ? null : transactionValue };
};

export const MetaMaskProvider = ({ children }: Props) => {
    const [ethereum, setEthereum] = useState<EthereumProvider | null>(null);
    const [account, setAccount] = useState<string | null>(null);
    const [chainId, setChainId] = useState<ValidChainId | null>(null);

    const isInstalledMetaMask = !!ethereum;
    const isCorrectChainId = chainId === requiredChainId;
    const isConnectedMetaMask = !!account && isCorrectChainId;

    // Infinitely every second watch for account changes
    useEffect(() => {
        if (!isInstalledMetaMask) return;

        const intervalId = setInterval(async () => {
            try {
                // The is a reason why we use web3 is because this method
                // returns accounts with both lowercase and uppercase characters
                const accounts = await web3.eth.getAccounts();

                if (isEmpty(accounts)) return setAccount(null);

                setAccount(accounts[0]);
            } catch (e) {
                console.log('[e]', e);
            }
        }, SECOND);

        return () => clearInterval(intervalId);
    }, [isInstalledMetaMask]);

    const handleGetChainId = useCallback(async () => {
        // This needs to be saved into a variable or TypeScript will complain about it
        const chainId = (await web3.eth.net.getId()) as ValidChainId;

        return chainId;
    }, []);

    const setCurrentChainId = useCallback(async () => {
        try {
            const currentChainId = await handleGetChainId();

            setChainId(currentChainId);
        } catch (e) {
            console.log('[e]', e);
        }
    }, [handleGetChainId]);

    // Detect MetaMask provider, set it to state and set current chain id
    useEffect(() => {
        (async () => {
            try {
                const provider = (await detectEthereumProvider()) as EthereumProvider;

                provider.on('chainChanged', setCurrentChainId);

                setEthereum(provider);
                setCurrentChainId();
            } catch (e) {
                console.log('[e]', e);
            }
        })();
    }, [setCurrentChainId]);

    const handleGetAccounts = useCallback(() => web3.eth.getAccounts(), []);

    const handleError = useCallback((e: MetaMaskError, onUnknownError: () => void) => {
        if ([REJECT_SIGN_CODE, ALREADY_PROCESSING_METAMASK_CODE].includes(e.code)) return;

        onUnknownError();
    }, []);

    const handleConnectToMetaMask = useCallback(async () => {
        const params = [metamaskChainsParams[requiredChainId]];

        await ethereum!.request({
            method: 'wallet_addEthereumChain',
            params
        });

        const currentChainId = await handleGetChainId();
        await ethereum!.request({ method: 'eth_requestAccounts' });

        setChainId(currentChainId);
    }, [ethereum, handleGetChainId]);

    const handleSign = useCallback(
        message => {
            const encodedMessage = web3.utils.utf8ToHex(message);

            // This line is ignored, because `sign` method has a third optional argument,
            // which TypeScript is complaining about
            // @ts-ignore
            return web3.eth.personal.sign(encodedMessage, account);
        },
        [account]
    );

    const handleGetTransactionReceipt = useCallback(
        (txId: string) =>
            ethereum!.request({
                method: 'eth_getTransactionReceipt',
                params: [txId]
            }) as Promise<TransactionReceiptResponse | null>,
        [ethereum]
    );

    const handleCheckAllowance = useCallback(
        async (value: string) => {
            const attributes = [account!, darsStakeContractAddress!];

            const allowedValue = await ethereum!.request({
                method: 'eth_call',
                params: [
                    {
                        from: account!,
                        to: lpTokenContractAddress!,
                        data: getEncodedFunctionCall(ALLOWANCE_METHOD_INTERFACE, attributes)
                    }
                ]
            });

            return getApprovementStatusAndValue(value, allowedValue);
        },
        [ethereum, account]
    );

    const handleApprove = useCallback(
        (value: string) => {
            const attributes = [darsStakeContractAddress!, value];

            return ethereum!.request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        from: account!,
                        to: lpTokenContractAddress!,
                        data: getEncodedFunctionCall(APPROVE_METHOD_INTERFACE, attributes)
                    }
                ]
            });
        },
        [ethereum, account]
    );

    const handleGetBalance = useCallback(
        async ({ from, to }: GetBalanceOptions) => {
            const attributes = [from];

            const rawResult = await ethereum!.request({
                method: 'eth_call',
                params: [
                    {
                        from,
                        to,
                        data: getEncodedFunctionCall(BALANCE_METHOD_INTERFACE, attributes)
                    }
                ]
            });

            const result = web3.eth.abi.decodeParameters(
                ['uint256'],
                rawResult
            ) as DecodedParameters<string>;

            const balance = result[0];

            return {
                raw: balance,
                rounded: getRoundedValue(balance)
            };
        },
        [ethereum]
    );

    const handleStakeTokens = useCallback(
        async amount => {
            const attributes = [amount];

            return ethereum!.request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        from: account!,
                        to: darsStakeContractAddress!,
                        data: getEncodedFunctionCall(STAKE_METHOD_INTERFACE, attributes)
                    }
                ]
            });
        },
        [ethereum, account]
    );

    const handleUnstakeTokens = useCallback(
        async amount => {
            const attributes = [amount];

            return ethereum!.request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        from: account!,
                        to: darsStakeContractAddress!,
                        data: getEncodedFunctionCall(UNSTAKE_METHOD_INTERFACE, attributes)
                    }
                ]
            });
        },
        [ethereum, account]
    );

    const handleGetUserInfo = useCallback(async () => {
        const attributes = [account!];

        const rawResult = (await ethereum!.request({
            method: 'eth_call',
            params: [
                {
                    from: account!,
                    to: darsStakeContractAddress!,
                    data: getEncodedFunctionCall(USER_INFO_METHOD_INTERFACE, attributes)
                }
            ]
        })) as string;

        const result = web3.eth.abi.decodeParameters(
            ['uint256', 'uint256', 'uint256'],
            rawResult
        ) as DecodedParameters<string>;

        const freezeDate = +result[0];
        const stakedTokensAmount = result[1];

        return {
            freezeDate: freezeDate * SECOND,
            stakedTokens: {
                raw: stakedTokensAmount,
                rounded: getRoundedValue(stakedTokensAmount)
            }
        };
    }, [ethereum, account]);

    const handleGetDividends = useCallback(async () => {
        const attributes = [account!];

        const rawResult = await ethereum!.request({
            method: 'eth_call',
            params: [
                {
                    from: account!,
                    to: darsStakeContractAddress!,
                    data: getEncodedFunctionCall(GET_DIVIDENDS_METHOD_INTERFACE, attributes)
                }
            ]
        });

        const result = web3.eth.abi.decodeParameters(
            ['uint256'],
            rawResult
        ) as DecodedParameters<string>;

        const dividendsAmount = result[0];

        return {
            raw: dividendsAmount,
            rounded: getRoundedValue(dividendsAmount)
        };
    }, [ethereum, account]);

    const handleWithdrawDividends = useCallback(
        () =>
            ethereum!.request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        from: account!,
                        to: darsStakeContractAddress!,
                        data: getEncodedFunctionCall(WITHDRAW_DIVIDENDS_METHOD_INTERFACE, [])
                    }
                ]
            }),
        [ethereum, account]
    );

    const handleCheckUnstakeStatus = useCallback(async () => {
        const attributes = [account!];

        const rawResult = await ethereum!.request({
            method: 'eth_call',
            params: [
                {
                    from: account!,
                    to: darsStakeContractAddress!,
                    data: getEncodedFunctionCall(CHECK_UNSTAKE_STATUS_METHOD_INTERFACE, attributes)
                }
            ]
        });

        const result = web3.eth.abi.decodeParameters(
            ['bool'],
            rawResult
        ) as DecodedParameters<boolean>;

        const areFrozenTokens = result[0];

        return areFrozenTokens;
    }, [ethereum, account]);

    const handleGetPoolInfo = useCallback(async () => {
        const rawResult = await ethereum!.request({
            method: 'eth_call',
            params: [
                {
                    from: account!,
                    to: darsStakeContractAddress!,
                    data: getEncodedFunctionCall(POOL_INFO_METHOD_INTERFACE, [])
                }
            ]
        });

        const result = web3.eth.abi.decodeParameters(
            ['uint256', 'uint256', 'uint256', 'uint256', 'uint256'],
            rawResult
        ) as DecodedParameters<string>;

        const stakedTokensAmount = result[1];
        const freezingPeriod = result[3];
        const totalDividendsAmount = result[4];

        return {
            totalStakedTokens: {
                raw: stakedTokensAmount,
                rounded: getRoundedValue(stakedTokensAmount, { decimalDigitsAmount: 2 })
            },
            totalDividends: {
                raw: totalDividendsAmount,
                rounded: getRoundedValue(totalDividendsAmount, { decimalDigitsAmount: 2 })
            },
            freezingPeriod: +freezingPeriod * SECOND
        };
    }, [ethereum, account]);

    const handleGetMinimumDistributeBalance = useCallback(async () => {
        const rawResult = await ethereum!.request({
            method: 'eth_call',
            params: [
                {
                    from: account!,
                    to: darsBasisContractAddress!,
                    data: getEncodedFunctionCall(MINIMUM_DISTRIBUTE_BALANCE_METHOD_INTERFACE, [])
                }
            ]
        });

        const result = web3.eth.abi.decodeParameters(
            ['uint256'],
            rawResult
        ) as DecodedParameters<string>;

        const minimumDistributeBalance = result[0];

        return minimumDistributeBalance;
    }, [ethereum, account]);

    const handleCheckPoolDistributionStatus = useCallback(async () => {
        const rawMinimumDistributeBalanceResult = await ethereum!.request({
            method: 'eth_call',
            params: [
                {
                    from: account!,
                    to: darsBasisContractAddress!,
                    data: getEncodedFunctionCall(MINIMUM_DISTRIBUTE_BALANCE_METHOD_INTERFACE, [])
                }
            ]
        });

        const rawDarsBasisBalanceResult = await ethereum!.request({
            method: 'eth_call',
            params: [
                {
                    from: darsBasisContractAddress!,
                    to: usdtContractAddress!,
                    data: getEncodedFunctionCall(BALANCE_METHOD_INTERFACE, [
                        darsBasisContractAddress!
                    ])
                }
            ]
        });

        const minimumDistributeBalanceResult = web3.eth.abi.decodeParameters(
            ['uint256'],
            rawMinimumDistributeBalanceResult
        ) as DecodedParameters<string>;

        const darsBasisBalanceResult = web3.eth.abi.decodeParameters(
            ['uint256'],
            rawDarsBasisBalanceResult
        ) as DecodedParameters<string>;

        const minimumDistributeBalance = minimumDistributeBalanceResult[0];
        const darsBasisBalance = darsBasisBalanceResult[0];

        const minimumDistributeBalanceBN = web3.utils.toBN(minimumDistributeBalance);
        const darsBasisTokensBN = web3.utils.toBN(darsBasisBalance);

        const isDisabledPoolDistribution = darsBasisTokensBN.lt(minimumDistributeBalanceBN);

        return isDisabledPoolDistribution;
    }, [ethereum, account]);

    const handleDistributePool = useCallback(
        () =>
            ethereum!.request({
                method: 'eth_sendTransaction',
                params: [
                    {
                        from: account!,
                        to: darsBasisContractAddress!,
                        data: getEncodedFunctionCall(DISTRIBUTE_POOL_METHOD_INTERFACE, [])
                    }
                ]
            }),
        [ethereum, account]
    );

    const handleGetDarsRate = useCallback(async () => {
        const rawResult = await ethereum!.request({
            method: 'eth_call',
            params: [
                {
                    from: account!,
                    to: darsStakeContractAddress!,
                    data: getEncodedFunctionCall(GET_DARS_RATE_METHOD_INTERFACE, [])
                }
            ]
        });

        const result = web3.eth.abi.decodeParameters(
            ['uint256'],
            rawResult
        ) as DecodedParameters<string>;

        const darsRate = result[0];

        return {
            raw: darsRate,
            rounded: getRoundedValue(darsRate)
        };
    }, [ethereum, account]);

    const contextValue = useMemo(
        () => ({
            ethereum,
            account,
            chainId,
            isInstalledMetaMask,
            isConnectedMetaMask,
            isCorrectChainId,
            onError: handleError,
            onGetAccounts: handleGetAccounts,
            onConnectToMetaMask: handleConnectToMetaMask,
            onSign: handleSign,
            onGetTransactionReceipt: handleGetTransactionReceipt,
            onGetBalance: handleGetBalance,
            onCheckAllowance: handleCheckAllowance,
            onApprove: handleApprove,
            onStakeTokens: handleStakeTokens,
            onUnstakeTokens: handleUnstakeTokens,
            onGetUserInfo: handleGetUserInfo,
            onGetDividends: handleGetDividends,
            onCheckUnstakeStatus: handleCheckUnstakeStatus,
            onGetPoolInfo: handleGetPoolInfo,
            onWithdrawDividends: handleWithdrawDividends,
            onDistributePool: handleDistributePool,
            onGetMinimumDistributeBalance: handleGetMinimumDistributeBalance,
            onCheckPoolDistributionStatus: handleCheckPoolDistributionStatus,
            onGetDarsRate: handleGetDarsRate
        }),
        [
            ethereum,
            account,
            chainId,
            isInstalledMetaMask,
            isConnectedMetaMask,
            isCorrectChainId,
            handleError,
            handleGetAccounts,
            handleConnectToMetaMask,
            handleSign,
            handleGetTransactionReceipt,
            handleGetBalance,
            handleCheckAllowance,
            handleApprove,
            handleStakeTokens,
            handleUnstakeTokens,
            handleGetUserInfo,
            handleGetDividends,
            handleCheckUnstakeStatus,
            handleGetPoolInfo,
            handleWithdrawDividends,
            handleDistributePool,
            handleGetMinimumDistributeBalance,
            handleCheckPoolDistributionStatus,
            handleGetDarsRate
        ]
    );

    return <MetaMaskContext.Provider value={contextValue}>{children}</MetaMaskContext.Provider>;
};
