import { useRef, forwardRef, KeyboardEvent } from 'react';

import { StyledWrapper, StyledCheckbox } from './styles';

type Props = {
    /**
     * Unique id for binding checkbox and label
     */
    id: string;
    /**
     * Unique checkbox field name
     */
    name: string;
    /**
     * Flag, which indicates that checkbox is disabled
     */
    disabled?: boolean;
    /**
     * Checkbox current value
     */
    value?: boolean;
};

export const Checkbox = forwardRef<HTMLDivElement, Props>(
    ({ value: isChecked = false, disabled = false, ...other }, forwardedRef) => {
        const checkboxRef = useRef<HTMLInputElement>(null);

        const handleClick = () => {
            // Simulate click on hidden checkbox input
            checkboxRef.current?.click();
        };

        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key !== 'Enter') return;

            // Simulate click on hidden checkbox input
            checkboxRef.current?.click();
        };

        return (
            <StyledWrapper>
                <input
                    ref={checkboxRef}
                    style={{ display: 'none' }}
                    type="checkbox"
                    checked={isChecked}
                    {...other}
                />

                <StyledCheckbox
                    ref={forwardedRef}
                    tabIndex={0}
                    role="checkbox"
                    isChecked={isChecked}
                    isDisabled={disabled}
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                />
            </StyledWrapper>
        );
    }
);
