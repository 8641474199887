import styled, { css } from 'styled-components/macro';

import { ExpandedThemedPropsType } from '../../../theme';

type Props = ExpandedThemedPropsType<{
    isAppearing?: boolean;
    isHiding?: boolean;
}>;

const DURATION = 300;

const applyAnimation = ({ isAppearing, isHiding }: Props) => {
    if (!isAppearing && !isHiding) return;

    let opacity;

    switch (true) {
        case isAppearing:
            opacity = 1;
            break;

        case isHiding:
            opacity = 0;
            break;

        default:
            return;
    }

    return css`
        opacity: ${opacity};
        transition: opacity ${DURATION}ms ease;
    `;
};

export const StyledBackdrop = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    ${applyAnimation}
`;
