import styled from 'styled-components/macro';

import { StyledText } from '../../../styles';

// STYLING:
const grayClr = '#8296A4';

export const StyledDetailsLabel = styled(StyledText).attrs({ size: 'md' })`
    color: ${grayClr};
    text-align: center;
`;
