import styled from 'styled-components/macro';

import { backgroundImageMixin } from '../../../utils';
import metamaskCircle from '../../../resources/images/metamask-circle.svg';

const size = '9.4rem';

export const StyledMetaMaskLogo = styled.div`
    width: ${size};
    height: ${size};
    border-radius: 50%;
    box-shadow: inset 0.5rem 0.4rem 1rem rgba(0, 0, 0, 0.25);
    position: relative;
    margin-bottom: 1.8rem;

    ${backgroundImageMixin({ url: metamaskCircle, size: 'cover' })}
`;
