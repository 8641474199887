import styled, { css } from 'styled-components/macro';

import { rgba } from '../../../utils';
import { ExpandedThemedPropsType } from '../../../theme';

type Props = ExpandedThemedPropsType<{
    fullscreen?: boolean;
}>;

const whiteClr = ({ theme }: Props) => theme.color.light[100];
const refinedWhiteClr = ({ theme }: Props) => rgba(theme.color.light[100], 0.2);

const applyFullScreen = ({ fullscreen }: Props) => {
    const bgClr = fullscreen ? whiteClr : refinedWhiteClr;
    const position = fullscreen ? 'fixed' : 'absolute';

    return css`
        background-color: ${bgClr};
        position: ${position};
    `;
};

export const StyledOverlay = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    ${applyFullScreen}
`;
