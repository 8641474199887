import styled, { css } from 'styled-components/macro';

import { backgroundImageMixin } from '../../../utils';
import { ExpandedThemedPropsType } from '../../../theme';
import circle from '../../../resources/images/gray-circle.svg';
import circleChecked from '../../../resources/images/gray-circle-checked.svg';

type Props = ExpandedThemedPropsType<{
    isChecked: boolean;
    isDisabled: boolean;
}>;

const size = '2rem';
// STYLING:
const focusOrangeClr = '#947a38';
const disabledBgColor = 'rgb(54 57 64)';

const icon = ({ isChecked }: Props) => (isChecked ? circleChecked : circle);

const applyDisabledStyles = ({ isDisabled }: Props) => {
    if (!isDisabled) return;

    return css`
        background-color: ${disabledBgColor};
    `;
};

export const StyledCheckbox = styled.div<Props>`
    width: ${size};
    height: ${size};
    border-radius: 50%;
    outline: 0;
    cursor: pointer;
    transition: box-shadow 200ms ease, background-color 200ms ease;

    ${backgroundImageMixin({ url: icon, size })}

    &:focus {
        box-shadow: inset 0.45rem 0.45rem 3.1rem rgba(0, 0, 0, 0.25), 0 0 0 0.3rem ${focusOrangeClr};
    }

    ${applyDisabledStyles}
`;
