import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const blackClr = ({ theme }: ThemedPropsType) => theme.color.dark[800];
const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledApp = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    background-color: ${blackClr};
    padding: 2rem;

    @media (min-width: ${lg}) {
        padding: 4.5rem 6.4rem 6.4rem;
    }
`;
