import styled from 'styled-components/macro';

import { StyledScroll } from '../../../styles';
import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledContent = styled(StyledScroll)`
    padding: 2rem;
    flex-grow: 1;

    @media (min-width: ${lg}) {
        padding: 0 9.3rem 6.3rem;
    }
`;
