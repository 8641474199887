import styled from 'styled-components/macro';

import { backgroundImageMixin } from '../../../utils';
import grayWallet from '../../../resources/images/gray-wallet.svg';

const size = '1.8rem';

export const StyledWalletIcon = styled.span`
    width: ${size};
    height: ${size};
    flex-shrink: 0;
    margin-right: 1.4rem;

    ${backgroundImageMixin({ url: grayWallet, size })}
`;
