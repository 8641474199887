import styled from 'styled-components/macro';

import { ToastType } from '../Toast';
import { ExpandedThemedPropsType } from '../../../theme';
import { backgroundImageMixin } from '../../../utils';
import checkCircle from '../../../resources/images/white-check-circle.svg';
import crossCircle from '../../../resources/images/white-cross-circle.svg';

type Props = ExpandedThemedPropsType<{
    $type: ToastType;
}>;

const ICONS = {
    success: checkCircle,
    error: crossCircle
};

const icon = ({ $type }: Props) => ICONS[$type];

const size = '2.6rem';

export const StyledIcon = styled.div<Props>`
    width: ${size};
    height: ${size};

    ${backgroundImageMixin({ url: icon, size })}
`;
