import styled from 'styled-components/macro';

import { StyledChase } from '../../../components/Loader';

export const StyledLoaderWrapper = styled.div`
    position: fixed;
    left: 2rem;
    bottom: 2rem;
    z-index: 3;
    display: flex;

    ${StyledChase} {
        margin-right: 2rem;
    }
`;
