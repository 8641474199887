import styled from 'styled-components/macro';

import { ThemedPropsType } from '../theme';

const orangeClr = ({ theme }: ThemedPropsType) => theme.color.primary[500];

// STYLING:
const lightGrayClr = '#eeeeee';
const size = '0.2rem';

export const StyledScroll = styled.div`
    overflow: auto;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
        height: ${size};
        width: ${size};
        background-color: ${lightGrayClr};
    }

    &::-webkit-scrollbar-thumb {
        height: ${size};
        border-radius: 0.3rem;
        box-shadow: inset 0 0 0.6rem ${orangeClr};
    }
`;
