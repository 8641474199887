import colorString from 'color-string';

type FormatType = 'hex' | 'rgb' | 'hsl' | 'keyword';

const DEFAULT_FORMAT_TYPE = 'hex';

/**
 * @description
 * Apply `alpha` channel to a color of any format
 */
export const rgba = (
    color: string,
    alpha: number = 1,
    formatType: FormatType = DEFAULT_FORMAT_TYPE
) => {
    // It could be `rgba` as well
    const rgbColor = colorString.get.rgb(color);

    // Invalid color will give `null`
    if (!rgbColor) return color;

    // Replace the existing alpha with a provided value
    const rgbaColor = [...rgbColor.slice(0, 3), alpha];

    return colorString.to[formatType](rgbaColor);
};
