import styled from 'styled-components/macro';

export const StyledOverlay = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
`;
