import styled from 'styled-components/macro';

import { ExpandedThemedPropsType } from '../theme';

type Props = ExpandedThemedPropsType<{
    size: 'sm' | 'md' | 'lg';
    bold?: boolean;
}>;

const fontSize = ({ size, theme }: Props) => theme.fontSize[size];
const fontWeight = ({ bold }: Props) => (bold ? 700 : 500);

export const StyledText = styled.span<Props>`
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    line-height: 140%;
`;
