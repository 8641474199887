import { css } from 'styled-components/macro';

type Props = {
    url: string | ((props: any) => string);
    size?: string;
    width?: string;
    height?: string;
    position?: string;
    repeat?: string;
};

export const backgroundImageMixin = ({
    url,
    size,
    width,
    height,
    position = 'center',
    repeat = 'no-repeat'
}: Props) => {
    const imageSize = size || `${width} ${height}`;

    return css`
        background-image: url(${url});
        background-size: ${imageSize};
        background-position: ${position};
        background-repeat: ${repeat};
    `;
};
