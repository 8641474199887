import { useEffect, ChangeEvent } from 'react';
import { useForm, useWatch, Controller, SubmitHandler } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Transition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import { Modal, Loader, Tooltip, Checkbox, MetaMaskError } from '../../components';
import {
    StyledBox,
    StyledButton,
    StyledText,
    StyledInput,
    StyledLabel,
    StyledValidationError,
    StyledQuestionIcon
} from '../../styles';
import {
    StyledPageWrapper,
    StyledContentWrapper,
    StyledTitle,
    StyledSubtitle,
    StyledControls,
    StyledStatsList,
    StyledStatsItem,
    StyledCaption,
    StyledBody,
    StyledSidebar,
    StyledWalletBox,
    StyledWalletIcon,
    StyledWalletWrapper,
    StyledMetaMaskLogo,
    StyledStatusIcon,
    StyledAddress,
    StyledDetailsBox,
    StyledDetailsList,
    StyledDetailsItem,
    StyledDetailsValue,
    StyledDetailsLabel,
    StyledCurrenciesWrapper,
    StyledCurrencyWrapper,
    StyledCurrencyLogo,
    StyledModalTitle,
    StyledFieldWrapper,
    StyledCheckboxWrapper,
    StyledErrorIcon,
    StyledLoaderWrapper,
    StyledFooter,
    StyledInstructionsBox,
    StyledInstructionLink,
    StyledForm
} from './styles';
import { useMetaMask, useToast, Balances } from '../../hooks';
import drsLogo from '../../resources/images/drs-round-currency.svg';
import usdtLogo from '../../resources/images/usdt-round-currency.svg';

type Props = {
    balances: Balances;
    isOpenStakingModal: boolean;
    isStaking: boolean;
    isUpdatingBalances: boolean;
    isUpdatingPoolDistributionStatus: boolean;
    isDisabledStaking: boolean;
    isDisabledUnstaking: boolean;
    isDisabledDividendsWithdrawal: boolean;
    isDisabledPoolDistribution: boolean;
    onToggleStakingModal: () => void;
    onStakeTokens: (amount: string) => Promise<void>;
    onUnstakeTokens: () => Promise<void>;
    onWithdrawDividends: () => Promise<void>;
    onDistributePool: () => Promise<void>;
};

type FormValues = {
    amount: string;
    shouldStakeAllTokens: boolean;
};

const { REACT_APP_STATIC_FILES_URL: staticFilesUrl } = process.env;

const LOADER_TIMEOUT = 1000;
const MINIMUM_VALUE_TO_STAKE = 1;
const SWAP_POOL_LINK =
    'https://pancakeswap.finance/info/pool/0xb5888e8ef180b143b74fb27ab86e4943f2f433a9';

const createUnfreezeDate = (
    freezingPeriod: number,
    freezeDate: number,
    areFrozenTokens: boolean
) => {
    if (!freezingPeriod || !freezeDate || !areFrozenTokens) return null;

    return new Date(freezeDate + freezingPeriod);
};

export const StakingPage = ({
    balances,
    isOpenStakingModal,
    isStaking,
    isUpdatingBalances,
    isUpdatingPoolDistributionStatus,
    isDisabledStaking,
    isDisabledUnstaking,
    isDisabledDividendsWithdrawal,
    isDisabledPoolDistribution,
    onToggleStakingModal,
    onStakeTokens,
    onUnstakeTokens,
    onWithdrawDividends,
    onDistributePool
}: Props) => {
    const {
        control,
        formState: { errors },
        handleSubmit,
        register,
        reset,
        getValues,
        setValue
    } = useForm<FormValues>();
    const shouldStakeAllTokens = useWatch({
        control,
        name: 'shouldStakeAllTokens',
        defaultValue: false
    });
    const { account, isConnectedMetaMask, onConnectToMetaMask, onError } = useMetaMask();
    const addToast = useToast();
    const { t } = useTranslation();

    const {
        lpTokens,
        usdtTokens,
        drsTokens,
        dividends,
        stakedTokens,
        totalStakedTokens,
        totalDividends,
        darsRate,
        freezingPeriod,
        freezeDate,
        areFrozenTokens
    } = balances;

    useEffect(() => {
        if (isOpenStakingModal) return;

        reset();
    }, [isOpenStakingModal, reset]);

    useEffect(() => {
        if (!shouldStakeAllTokens) return;

        setValue('amount', lpTokens.rounded);
    }, [shouldStakeAllTokens, lpTokens.rounded, setValue]);

    const unfreezeDate = createUnfreezeDate(freezingPeriod, freezeDate, areFrozenTokens);
    const hasError = 'amount' in errors;
    const isUpdating = isUpdatingBalances || isUpdatingPoolDistributionStatus;

    const handleConnectToMetaMask = async () => {
        try {
            await onConnectToMetaMask();
        } catch (e) {
            onError(e as MetaMaskError, () => {
                const unknownErrorMessage = t(
                    'Произошла неизвестная ошибка. Пожалуйста, попробуйте позже'
                );

                console.log('[e]', e);
                // TODO: We should add correct notifications on getting errors while adding/switching chains
                addToast({ type: 'error', message: unknownErrorMessage });
            });
        }
    };

    const handleChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        // Leave only digits and a single dot
        const prevValue = getValues('amount');
        const formattedValue = value.replace(/[^\d.]/g, '');
        const nextValue = isNaN(+formattedValue) ? prevValue : formattedValue;

        setValue('amount', nextValue);
    };

    const handleStakeTokens: SubmitHandler<FormValues> = ({ amount }) => {
        onStakeTokens(amount);
    };

    const stakeButtonText = unfreezeDate ? t('Достекировать') : t('Застекировать');

    const validationErrors = {
        required: t('Это поле обязательно для заполнения'),
        max: t('Недостаточно LP-токенов'),
        validate: t('Неверное значение')
    };

    const instructions = [
        {
            id: 1,
            text: t('SWAP токенов DARS(DRS) в BSC'),
            url: `${staticFilesUrl}/SWAP_token_DARS_DRS_to_BSC.pdf`
        },
        {
            id: 2,
            text: t('Установка Metamask для Chrome'),
            url: `${staticFilesUrl}/Setup_Metamask_for_Chrome.pdf`
        },
        {
            id: 3,
            text: t('Установка Metamask для IOS/Android'),
            url: `${staticFilesUrl}/Setup_Metamask_for_IOS_Android.pdf`
        },
        {
            id: 4,
            text: t('Инструкция по стекированию'),
            url: `${staticFilesUrl}/Staking.pdf`
        }
    ];

    return (
        <>
            <StyledPageWrapper>
                <StyledBody>
                    <StyledBox size="lg" bg="primary">
                        <StyledTitle>{t('Работа с токенами')}</StyledTitle>

                        {/* Balance */}
                        <StyledContentWrapper>
                            <StyledSubtitle>
                                {t('Баланс')}
                                <Tooltip
                                    content={t('Баланс токенов DRS и USDT на Вашем кошельке')}
                                    placement="right"
                                >
                                    <StyledQuestionIcon />
                                </Tooltip>
                            </StyledSubtitle>

                            <StyledBox size="md" bg="secondary">
                                <StyledCurrenciesWrapper>
                                    <StyledCurrencyWrapper>
                                        <StyledCurrencyLogo src={drsLogo} alt="DRS" title="DRS" />

                                        <div className="d-flex column">
                                            <StyledCaption>DRS</StyledCaption>

                                            <StyledText size="lg" bold>
                                                {drsTokens.rounded}
                                            </StyledText>
                                        </div>
                                    </StyledCurrencyWrapper>

                                    <StyledCurrencyWrapper>
                                        <StyledCurrencyLogo
                                            src={usdtLogo}
                                            alt="USDT"
                                            title="USDT"
                                        />

                                        <div className="d-flex column">
                                            <StyledCaption>USDT</StyledCaption>

                                            <StyledText size="lg" bold>
                                                {usdtTokens.rounded}
                                            </StyledText>
                                        </div>
                                    </StyledCurrencyWrapper>
                                </StyledCurrenciesWrapper>

                                <StyledControls>
                                    <StyledButton
                                        as="a"
                                        href={SWAP_POOL_LINK}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="primary"
                                        title={t('Купить DRS')}
                                    >
                                        {t('Купить DRS')}
                                    </StyledButton>

                                    <StyledButton
                                        as="a"
                                        href={SWAP_POOL_LINK}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        variant="secondary"
                                        title={t('Хочу LP-токен')}
                                    >
                                        {t('Хочу LP-токен')}
                                    </StyledButton>

                                    <StyledButton
                                        variant="secondary"
                                        title={t('Пополнить пул')}
                                        disabled={isDisabledPoolDistribution}
                                        onClick={onDistributePool}
                                    >
                                        {t('Пополнить пул')}
                                    </StyledButton>
                                </StyledControls>
                            </StyledBox>
                        </StyledContentWrapper>

                        {/* Staking */}
                        <StyledContentWrapper>
                            <StyledSubtitle>
                                {t('Стекирование')}
                                <Tooltip
                                    content={t(
                                        'Для получения LP-токенов необходимо внести в обменный пул пару DRS+USDT. Стекирование LP-токенов дает возможность получения пассивного дохода. Для этого необходимо застекировать свои LP-токены минимум на 72 часа. Добавление новых LP-токенов возможно в любое время, однако эта сумма будет добавлена к текущей и полученная сумма будет перестекирована на 72 часа. До истечения минимального срока стекирования функция вывода LP-токенов и прибыли будет неактивна. При этом, прибыль можно вывести в любое желаемое время'
                                    )}
                                    placement="right"
                                >
                                    <StyledQuestionIcon />
                                </Tooltip>
                            </StyledSubtitle>

                            <StyledBox size="md" bg="secondary">
                                <StyledStatsList>
                                    <StyledStatsItem>
                                        <StyledCaption>{t('Доступно')}</StyledCaption>

                                        <StyledText size="lg" bold>
                                            LP {lpTokens.rounded}
                                        </StyledText>
                                    </StyledStatsItem>

                                    <StyledStatsItem>
                                        <StyledCaption>{t('Застекировано')}</StyledCaption>

                                        <StyledText size="lg" bold>
                                            {stakedTokens.rounded}
                                        </StyledText>
                                    </StyledStatsItem>

                                    <StyledStatsItem>
                                        <StyledCaption>{t('Доход')}</StyledCaption>

                                        <StyledText size="lg" bold>
                                            USDT {dividends.rounded}
                                        </StyledText>
                                    </StyledStatsItem>

                                    <StyledStatsItem>
                                        <StyledCaption>{t('Дата разморозки')}</StyledCaption>

                                        <StyledText size="lg" bold>
                                            {unfreezeDate ? unfreezeDate.toLocaleDateString() : '-'}
                                        </StyledText>
                                    </StyledStatsItem>
                                </StyledStatsList>

                                <StyledControls>
                                    <StyledButton
                                        variant="primary"
                                        title={stakeButtonText}
                                        disabled={isDisabledStaking}
                                        onClick={onToggleStakingModal}
                                    >
                                        {stakeButtonText}
                                    </StyledButton>

                                    <StyledButton
                                        variant="secondary"
                                        disabled={isDisabledDividendsWithdrawal}
                                        title={t('Вывести прибыль')}
                                        onClick={onWithdrawDividends}
                                    >
                                        {t('Вывести прибыль')}
                                    </StyledButton>

                                    <StyledButton
                                        variant="secondary"
                                        disabled={isDisabledUnstaking}
                                        title={t('Вывести LP-токен+прибыль')}
                                        onClick={onUnstakeTokens}
                                    >
                                        {t('Вывести LP-токен+прибыль')}
                                    </StyledButton>
                                </StyledControls>
                            </StyledBox>
                        </StyledContentWrapper>
                    </StyledBox>
                </StyledBody>

                <StyledSidebar>
                    {/* MetaMask */}
                    <StyledWalletBox>
                        <StyledMetaMaskLogo>
                            <StyledStatusIcon active={isConnectedMetaMask} />
                        </StyledMetaMaskLogo>

                        <StyledText size="lg" bold>
                            {isConnectedMetaMask
                                ? t('Кошелек подключен')
                                : t('Кошелек не подключен')}
                        </StyledText>

                        <StyledWalletWrapper>
                            <StyledWalletIcon />
                            <StyledAddress>{account || '-'}</StyledAddress>
                        </StyledWalletWrapper>

                        <StyledButton
                            variant="primary"
                            disabled={isConnectedMetaMask}
                            title={t('Подключить')}
                            onClick={handleConnectToMetaMask}
                        >
                            {t('Подключить')}
                        </StyledButton>
                    </StyledWalletBox>

                    {/* Stats */}
                    <StyledDetailsBox>
                        <StyledDetailsList>
                            <StyledDetailsItem>
                                <StyledDetailsValue>{totalStakedTokens.rounded}</StyledDetailsValue>
                                <StyledDetailsLabel>{t('Всего застекировано')}</StyledDetailsLabel>
                            </StyledDetailsItem>

                            <StyledDetailsItem>
                                <StyledDetailsValue>{totalDividends.rounded}</StyledDetailsValue>

                                <StyledDetailsLabel>
                                    {t('Выплачено вознаграждения (USDT)')}
                                </StyledDetailsLabel>
                            </StyledDetailsItem>

                            <StyledDetailsItem>
                                <StyledDetailsValue>{darsRate.rounded}</StyledDetailsValue>
                                <StyledDetailsLabel>{t('Курс токена DRS')}</StyledDetailsLabel>
                            </StyledDetailsItem>
                        </StyledDetailsList>
                    </StyledDetailsBox>
                </StyledSidebar>

                <StyledFooter>
                    <StyledInstructionsBox>
                        {instructions.map(({ id, text, url }) => (
                            <StyledInstructionLink
                                key={id}
                                href={url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {text}
                            </StyledInstructionLink>
                        ))}
                    </StyledInstructionsBox>
                </StyledFooter>
            </StyledPageWrapper>

            <Transition in={isUpdating} timeout={LOADER_TIMEOUT} mountOnEnter unmountOnExit>
                {() => (
                    <StyledLoaderWrapper>
                        <Loader size="sm" />
                        <span>{t('Обновление...')}</span>
                    </StyledLoaderWrapper>
                )}
            </Transition>

            <Modal
                isOpen={isOpenStakingModal}
                closeOnEsc={!isStaking}
                onClose={onToggleStakingModal}
            >
                <Modal.Header>
                    <Modal.CloseButton
                        title={t('Закрыть')}
                        disabled={isStaking}
                        onClick={onToggleStakingModal}
                    />
                </Modal.Header>

                <Modal.Content>
                    <StyledForm
                        noValidate
                        autoComplete="off"
                        onSubmit={handleSubmit(handleStakeTokens)}
                    >
                        <StyledModalTitle>{t('Стекирование')}</StyledModalTitle>

                        <StyledFieldWrapper>
                            <StyledLabel required>{t('Введите количество LP-токенов')}</StyledLabel>

                            <div className="relative">
                                <StyledInput
                                    {...register('amount', {
                                        required: t('Неверное значение') as string,
                                        max: {
                                            message: validationErrors.max,
                                            value: lpTokens.rounded
                                        },
                                        validate: (value: string) => {
                                            if (+value >= MINIMUM_VALUE_TO_STAKE) return;

                                            return validationErrors.validate;
                                        }
                                    })}
                                    placeholder={t('Введите количество')}
                                    defaultValue={0}
                                    readOnly={isStaking || shouldStakeAllTokens}
                                    error={hasError}
                                    onInput={handleChangeAmount}
                                />

                                {hasError && <StyledErrorIcon />}

                                <ErrorMessage
                                    errors={errors}
                                    name="amount"
                                    as={StyledValidationError}
                                />
                            </div>
                        </StyledFieldWrapper>

                        <StyledCheckboxWrapper>
                            <Controller
                                control={control}
                                name="shouldStakeAllTokens"
                                defaultValue={false}
                                render={({ field }) => (
                                    <Checkbox id="all-tokens" disabled={isStaking} {...field} />
                                )}
                            />

                            <label htmlFor="all-tokens">
                                {t('Ввести все доступные токены')} ({lpTokens.rounded})
                            </label>
                        </StyledCheckboxWrapper>

                        <StyledButton
                            variant="primary"
                            disabled={isDisabledStaking || isStaking}
                            title={t('Застекировать')}
                        >
                            {isStaking && <Loader size="sm" overlay />}
                            {t('Застекировать')}
                        </StyledButton>
                    </StyledForm>
                </Modal.Content>
            </Modal>
        </>
    );
};
