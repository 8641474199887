import { Switch, Route, Redirect } from 'react-router-dom';

import { StakingPageContainer } from '../../containers';
import { AppHeader } from '../AppHeader';
import { StyledApp, StyledMain } from './styles';

export const App = () => (
    <StyledApp>
        <AppHeader />

        <StyledMain>
            <Switch>
                <Route path="/" exact>
                    <StakingPageContainer />
                </Route>

                <Redirect to="/" />
            </Switch>
        </StyledMain>
    </StyledApp>
);
