import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledCurrenciesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;

    @media (min-width: ${lg}) {
        flex-direction: row;
    }
`;
