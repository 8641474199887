import { ToastContainerProps } from 'react-toastify';

import { StyledToaster } from './styles';

const DEFAULT_POSITION = 'top-left';
const DEFAULT_LIFE_TIME = 15000;
const DEFAULT_DRAG_PERCENT = 80;

export const ToastProvider = ({
    position = DEFAULT_POSITION,
    autoClose = DEFAULT_LIFE_TIME,
    closeButton = false,
    hideProgressBar = false,
    pauseOnHover = true,
    pauseOnFocusLoss = true,
    closeOnClick = false,
    draggable = true,
    draggablePercent = DEFAULT_DRAG_PERCENT,
    newestOnTop = false,
    ...other
}: ToastContainerProps) => (
    <StyledToaster
        position={position}
        autoClose={autoClose}
        closeButton={closeButton}
        hideProgressBar={hideProgressBar}
        pauseOnHover={pauseOnHover}
        pauseOnFocusLoss={pauseOnFocusLoss}
        closeOnClick={closeOnClick}
        draggable={draggable}
        draggablePercent={draggablePercent}
        newestOnTop={newestOnTop}
        {...other}
    />
);
