import { useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const DEFAULT_ATTR_NAME = 'data-scroll-gap';
const DEFAULT_TARGET_NODE = document.getElementById('root');

type Options = {
    attrName?: string;
    selector?: string;
    targetNode?: HTMLElement;
    getShiftValue?: typeof getShiftValueInRem;
};

const getShiftValueInRem = (value: number) => `${value / 10}rem`;

/**
 * @description
 * Prevent scroll on `body` and add a `padding-right` to it and elements, which will have a
 * particular data attribute (by default, `data-scroll-gap`)
 */
export const useBodyScrollLock = (options: Options = {}) => {
    const {
        attrName = DEFAULT_ATTR_NAME,
        selector = `[${attrName}]`,
        targetNode = DEFAULT_TARGET_NODE,
        getShiftValue = getShiftValueInRem
    } = options;

    useEffect(() => {
        const html = document.documentElement;
        const scrollWidth = window.innerWidth - html.clientWidth;

        const nodes = document.querySelectorAll(selector) as NodeListOf<HTMLElement>;

        for (const node of nodes) {
            // Get element's own `padding` from data attribute and combine it with `scrollWidth`
            const gap = +node.getAttribute(attrName)! || 0;
            const shift = getShiftValue(gap + scrollWidth);

            node.style.cssText = `
                overflow: hidden;
                padding-right: ${shift};
            `;
        }

        disableBodyScroll(targetNode!, { reserveScrollBarGap: true });

        return () => {
            nodes.forEach(node => node.removeAttribute('style'));
            enableBodyScroll(targetNode!);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
