import { createGlobalStyle } from 'styled-components/macro';

export const GlobalUtility = createGlobalStyle`
    .d-flex {
        display: flex;
    }

    .column {
        flex-direction: column;
    }

    .jc-center {
        justify-content: center;
    }

    .jc-between {
        justify-content: space-between;
    }

    .jc-around {
        justify-content: space-around;
    }

    .jc-end {
        justify-content: flex-end;
    }

    .ai-center {
        align-items: center;
    }

    .ai-start {
        align-items: flex-start;
    }

    .ai-end {
        align-items: flex-end;
    }

    .soon {
        position: relative;
    }

    .relative {
        position: relative;
    }
`;
