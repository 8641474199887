import styled from 'styled-components/macro';

import { StyledHeading } from '../../../styles';
import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledTitle = styled(StyledHeading).attrs({ size: 'lg' })`
    margin-bottom: 2.8rem;

    @media (min-width: ${lg}) {
        margin-bottom: 3.6rem;
    }
`;
