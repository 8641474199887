import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledInstructionLink = styled.a`
    text-decoration: underline;
    transition: text-decoration-color 200ms ease;

    &:hover {
        text-decoration-color: transparent;
    }

    &:not(:last-child) {
        margin-bottom: 1rem;

        @media (min-width: ${lg}) {
            margin-bottom: 0;
            margin-right: 3rem;
        }
    }
`;
