import styled from 'styled-components/macro';
import { css, keyframes } from 'styled-components/macro';

import { LoaderSize } from '../Loader';
import { ExpandedThemedPropsType } from '../../../theme';

type Props = ExpandedThemedPropsType<{
    size: LoaderSize;
}>;

const skChase = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const SIZES = {
    sm: '2rem',
    md: '4rem',
    lg: '6rem'
};

const applySize = ({ size }: Props) => {
    const chaseSize = SIZES[size];

    return css`
        width: ${chaseSize};
        height: ${chaseSize};
    `;
};

export const StyledChase = styled.div`
    position: relative;
    animation: ${skChase} 2500ms infinite linear both;

    ${applySize}
`;
