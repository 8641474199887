import { createGlobalStyle } from 'styled-components/macro';

import { ThemedPropsType } from '../theme';

const whiteClr = ({ theme }: ThemedPropsType) => theme.color.light[100];
const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const GlobalTooltips = createGlobalStyle`
    .rc-tooltip.rc-tooltip-zoom-appear,
    .rc-tooltip.rc-tooltip-zoom-enter {
        opacity: 0;
    }

    .rc-tooltip.rc-tooltip-zoom-enter,
    .rc-tooltip.rc-tooltip-zoom-leave {
        display: block;
    }

    .rc-tooltip-zoom-enter,
    .rc-tooltip-zoom-appear {
        opacity: 0;
        animation-duration: 300ms;
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
        animation-play-state: paused;
    }

    .rc-tooltip-zoom-leave {
        animation-duration: 300ms;
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
        animation-play-state: paused;
    }

    .rc-tooltip-zoom-enter.rc-tooltip-zoom-enter-active,
    .rc-tooltip-zoom-appear.rc-tooltip-zoom-appear-active {
        animation-name: rcToolTipZoomIn;
        animation-play-state: running;
    }

    .rc-tooltip-zoom-leave.rc-tooltip-zoom-leave-active {
        animation-name: rcToolTipZoomOut;
        animation-play-state: running;
    }

    @keyframes rcToolTipZoomIn {
        0% {
            opacity: 0;
            transform-origin: 50% 50%;
            transform: scale(0, 0);
        }
        100% {
            opacity: 1;
            transform-origin: 50% 50%;
            transform: scale(1, 1);
        }
    }

    @keyframes rcToolTipZoomOut {
        0% {
            opacity: 1;
            transform-origin: 50% 50%;
            transform: scale(1, 1);
        }
        100% {
            opacity: 0;
            transform-origin: 50% 50%;
            transform: scale(0, 0);
        }
    }

    .rc-tooltip {
        position: absolute;
        display: block;
        visibility: visible;
    }

    .rc-tooltip-hidden {
        display: none;
    }

    .rc-tooltip-placement-top,
    .rc-tooltip-placement-topLeft,
    .rc-tooltip-placement-topRight {
        padding: 0.5rem 0 0.9rem 0;
    }

    .rc-tooltip-placement-right,
    .rc-tooltip-placement-rightTop,
    .rc-tooltip-placement-rightBottom {
        padding: 0 0.5rem 0 0.9rem;
    }

    .rc-tooltip-placement-bottom,
    .rc-tooltip-placement-bottomLeft,
    .rc-tooltip-placement-bottomRight {
        padding: 0.9rem 0 0.5rem 0;
    }

    .rc-tooltip-placement-left,
    .rc-tooltip-placement-leftTop,
    .rc-tooltip-placement-leftBottom {
        padding: 0 0.9rem 0 0.5rem;
    }

    .rc-tooltip-arrow {
        display: none;
        
        @media (min-width: ${lg}) {
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-color: transparent;
            border-style: solid;
        }
    }

    .rc-tooltip-placement-top .rc-tooltip-arrow,
    .rc-tooltip-placement-topLeft .rc-tooltip-arrow,
    .rc-tooltip-placement-topRight .rc-tooltip-arrow {
        bottom: 0.4rem;
        margin-left: -0.5rem;
        border-width: 0.5rem 0.5rem 0;
        border-top-color: ${whiteClr};
    }

    .rc-tooltip-placement-top .rc-tooltip-arrow {
        left: 50%;
    }

    .rc-tooltip-placement-topLeft .rc-tooltip-arrow {
        left: 15%;
    }

    .rc-tooltip-placement-topRight .rc-tooltip-arrow {
        right: 15%;
    }

    .rc-tooltip-placement-right .rc-tooltip-arrow,
    .rc-tooltip-placement-rightTop .rc-tooltip-arrow,
    .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
        left: 0.4rem;
        margin-top: -0.5rem;
        border-width: 0.5rem 0.5rem 0.5rem 0;
        border-right-color: ${whiteClr};
    }

    .rc-tooltip-placement-right .rc-tooltip-arrow {
        top: 50%;
    }

    .rc-tooltip-placement-rightTop .rc-tooltip-arrow {
        top: 15%;
        margin-top: 0;
    }

    .rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
        bottom: 15%;
    }

    .rc-tooltip-placement-left .rc-tooltip-arrow,
    .rc-tooltip-placement-leftTop .rc-tooltip-arrow,
    .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
        right: 0.4rem;
        margin-top: -0.5rem;
        border-width: 0.5rem 0 0.5rem 0.5rem;
        border-left-color: ${whiteClr};
    }

    .rc-tooltip-placement-left .rc-tooltip-arrow {
        top: 50%;
    }

    .rc-tooltip-placement-leftTop .rc-tooltip-arrow {
        top: 15%;
        margin-top: 0;
    }

    .rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
        bottom: 15%;
    }

    .rc-tooltip-placement-bottom .rc-tooltip-arrow,
    .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
    .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
        top: 0.4rem;
        margin-left: -0.5rem;
        border-width: 0 0.5rem 0.5rem;
        border-bottom-color: ${whiteClr};
    }

    .rc-tooltip-placement-bottom .rc-tooltip-arrow {
        left: 50%;
    }

    .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
        left: 15%;
    }

    .rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
        right: 15%;
    }
`;
