import styled from 'styled-components/macro';

import { StyledText } from '../../../styles';

// STYLING:
const grayClr = '#8296A4';

export const StyledCaption = styled(StyledText).attrs({ size: 'sm' })`
    display: inline-block;
    color: ${grayClr};
    text-transform: uppercase;
    margin-bottom: 0.8rem;
`;
