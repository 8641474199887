import styled, { css } from 'styled-components/macro';

import { ExpandedThemedPropsType } from '../theme';

type Props = ExpandedThemedPropsType<{
    error?: boolean;
}>;

const fontFamily = ({ theme }: Props) => theme.fontFamily.primary;
const fontSize = ({ theme }: Props) => theme.fontSize.md;
const borderRadius = ({ theme }: Props) => theme.borderRadius.sm;
const whiteClr = ({ theme }: Props) => theme.color.light[100];

// STYLING:
const darkClr = '#21262D';
const redClr = '#FF5F5F';
const focusOrangeClr = '#947a38';
const disabledBgColor = 'rgba(188, 188, 188, 0.15)';

const applyError = ({ error }: Props) => {
    const borderClr = error ? redClr : darkClr;
    const padding = error ? '1.6rem 5.3rem 1.6rem 1.8rem' : '1.6rem 1.8rem';
    const shadowClr = error ? redClr : focusOrangeClr;

    return css`
        border: 0.1rem solid ${borderClr};
        padding: ${padding};

        &:focus:not(:read-only) {
            border-color: ${darkClr};
            box-shadow: inset 0.45rem 0.45rem 3.1rem rgba(0, 0, 0, 0.25), 0 0 0 0.4rem ${shadowClr};
        }
    `;
};

export const StyledInput = styled.input.attrs(({ type = 'text' }) => ({ type }))`
    display: block;
    width: 100%;
    outline: 0;
    height: 5rem;
    color: ${whiteClr};
    font-family: ${fontFamily};
    font-size: ${fontSize};
    background-color: ${darkClr};
    box-shadow: inset 0.45rem 0.45rem 3.1rem rgba(0, 0, 0, 0.25);
    border-radius: ${borderRadius};
    transition: border-color 200ms ease, box-shadow 200ms ease, background-color 200ms ease;

    &:read-only {
        background-color: ${disabledBgColor};
        border-color: transparent;
    }

    ${applyError}
`;
