import styled, { css } from 'styled-components/macro';

import { StyledOverlay } from '../components/Loader';
import { ExpandedThemedPropsType } from '../theme';

type Props = ExpandedThemedPropsType<{
    variant: 'primary' | 'secondary';
}>;

const fontSize = ({ theme }: Props) => theme.fontSize.md;
const fontFamily = ({ theme }: Props) => theme.fontFamily.primary;
const borderRadius = ({ theme }: Props) => theme.borderRadius.sm;
const gradient = ({ theme }: Props) => theme.gradient.primary;
const grayClr = ({ theme }: Props) => theme.color.dark[300];
const blackClr = ({ theme }: Props) => theme.color.dark[900];
const lg = ({ theme }: Props) => theme.media.lg;

/* STYLING: */
const refinedOrangeClr = '#fddf964d';
const disabledTextClr = '#5b5e65';
const disabledBgClr = '#32363e';
const disabledBorderClr = '#32363e';
const focusOrangeClr = '#947a38';
const focusGrayClr = '#374148';
const hoverGrayClr = '#8080801a';

const applyVariant = ({ variant }: Props) => {
    switch (variant) {
        case 'primary':
            return css`
                color: ${blackClr};
                background-image: ${gradient};
                box-shadow: 0 0.4rem 2.5rem ${refinedOrangeClr};
                transition: color 200ms ease, box-shadow 200ms ease, filter 200ms ease;

                @media (min-width: ${lg}) {
                    padding: 1.6rem 5.3rem;
                }

                &:not(:disabled):not(:focus):hover {
                    filter: brightness(1.08);
                }

                &:focus {
                    box-shadow: 0 0.4rem 2.5rem ${refinedOrangeClr}, 0 0 0 0.4rem ${focusOrangeClr};
                }

                &:disabled {
                    background-image: none;
                    background-color: ${disabledBgClr};
                    box-shadow: 2.5rem 2.5rem 10rem rgba(0, 0, 0, 0.1);
                }
            `;

        case 'secondary':
            return css`
                background-color: transparent;
                border: 0.1rem solid ${grayClr};
                color: ${grayClr};
                transition: color 200ms ease, border-color 200ms ease, box-shadow 200ms ease,
                    background-color 200ms ease;

                @media (min-width: ${lg}) {
                    padding: 1.5rem 5.3rem;
                }

                &:not(:disabled):not(:focus):hover {
                    background-color: ${hoverGrayClr};
                    border-color: ${hoverGrayClr};
                }

                &:focus {
                    border-color: ${focusGrayClr};
                    box-shadow: 0 0 0 0.4rem ${focusGrayClr};
                }

                &:disabled {
                    border-color: ${disabledBorderClr};
                }
            `;
    }
};

export const StyledButton = styled.button<Props>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: ${fontFamily};
    font-size: ${fontSize};
    font-weight: 500;
    line-height: 1.8rem;
    height: 5rem;
    cursor: pointer;
    border: none;
    outline: 0;
    border-radius: ${borderRadius};
    position: relative;

    &:disabled {
        cursor: default;
        color: ${disabledTextClr};

        ${StyledOverlay} {
            border-radius: ${borderRadius};
        }
    }

    ${applyVariant}
`;
