import { ReactNode } from 'react';
import { ToastContentProps } from 'react-toastify';

import {
    StyledToast,
    StyledIconWrapper,
    StyledBody,
    StyledCloseButton,
    StyledButtonWrapper,
    StyledIcon
} from './styles';

export type ToastType = 'success' | 'error';

type Props = ToastContentProps & {
    /**
     * Type of toast
     */
    type: ToastType;
    /**
     * Toast content
     */
    children: ReactNode;
};

export const Toast = ({ type, children, closeToast }: Props) => (
    <StyledToast>
        <StyledIconWrapper $type={type}>
            <StyledIcon $type={type} />
        </StyledIconWrapper>

        <StyledBody>{children}</StyledBody>

        <StyledButtonWrapper>
            <StyledCloseButton title="Close" onClick={closeToast} />
        </StyledButtonWrapper>
    </StyledToast>
);
