import { useEffect, forwardRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from 'react-transition-group';
import FocusTrap from 'focus-trap-react';

import { Backdrop } from '../Backdrop';
import {
    StyledCloseButton,
    StyledHeader,
    StyledModal,
    StyledContent,
    StyledFooter,
    StyledOverlay,
    StyledTitle
} from './styles';

const TIMEOUT = 300;

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl';

type Props = {
    /**
     * Control if the modal is open or not.
     */
    isOpen: boolean;
    /**
     * Sets modal width
     * @default 'md'
     */
    size?: ModalSize;
    /**
     * Optional styling object
     */
    style?: Object;
    /**
     * Id attribute for modal
     */
    modalId?: string;
    /**
     * Indicates html node which will act as modal container
     */
    portalNode?: HTMLElement;
    /**
     * Is the modal closable on pressing esc key
     * @default true
     */
    closeOnEsc?: boolean;
    /**
     * Modal content to render
     */
    children: ReactNode;
    /**
     * Function for closing modal
     */
    onClose: () => void;
};

const ModalComponent = forwardRef<HTMLDivElement, Props>(
    (
        { size, modalId, isOpen, portalNode, closeOnEsc = true, children, style, onClose },
        forwardedRef
    ) => {
        useEffect(() => {
            if (!closeOnEsc) return;

            const handleEscKeyDown = (e: KeyboardEvent) => {
                if (e.key !== 'Escape') return;

                onClose();
            };

            document.addEventListener('keydown', handleEscKeyDown);

            return () => document.removeEventListener('keydown', handleEscKeyDown);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [closeOnEsc]);

        return (
            <Transition in={isOpen} timeout={TIMEOUT} mountOnEnter unmountOnExit>
                {state => {
                    const isAppearing = ['entering', 'entered'].includes(state);
                    const isHiding = ['exiting', 'exited'].includes(state);

                    return (
                        <>
                            <Backdrop isAppearing={isAppearing} isHiding={isHiding} />

                            {createPortal(
                                <StyledOverlay>
                                    <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
                                        <StyledModal
                                            ref={forwardedRef}
                                            id={modalId}
                                            size={size}
                                            isAppearing={isAppearing}
                                            isHiding={isHiding}
                                            style={style}
                                            onClick={e => e.stopPropagation()}
                                        >
                                            {children}
                                        </StyledModal>
                                    </FocusTrap>
                                </StyledOverlay>,
                                portalNode || document.getElementById('portal-root')!
                            )}
                        </>
                    );
                }}
            </Transition>
        );
    }
);

// A workaround for adding static props to a component
export const Modal = Object.assign(ModalComponent, {
    Header: StyledHeader,
    Title: StyledTitle,
    CloseButton: StyledCloseButton,
    Content: StyledContent,
    Footer: StyledFooter
});
