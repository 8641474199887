import { ThemeProps, ThemedStyledProps } from 'styled-components/macro';

export type ThemeType = typeof theme;
// Type, which is used for interpolated functions, which have `theme` prop
export type ThemedPropsType = ThemeProps<ThemeType>;
// Type, which is used for interpolated functions, which could have `theme` and other props
export type ExpandedThemedPropsType<T> = ThemedStyledProps<T, ThemeType>;

export const theme = {
    color: {
        light: {
            100: '#FFFFFF'
        },
        dark: {
            300: '#6C7E8C',
            400: '#4D5465',
            500: '#2C3A44',
            600: '#272B34',
            700: '#191D22',
            800: '#0C1115',
            900: '#000000'
        },
        primary: {
            500: '#FDDF96',
            600: '#F9B100'
        },
        default: '#FFFFFF'
    },
    gradient: {
        primary: 'linear-gradient(300.2deg, #F9B100 -21.64%, #FDDF96 51.94%)',
        secondary: 'linear-gradient(227.25deg, #191D22 25.98%, rgba(77, 84, 101, 0) 106.46%)',
        tertiary: 'linear-gradient(188.25deg, #272B34 25.14%, rgba(44, 58, 68, 0) 109.88%)'
    },
    fontFamily: {
        primary: 'Gilroy, sans-serif',
        default: 'Gilroy, sans-serif'
    },
    fontSize: {
        sm: '1.4rem',
        md: '1.6rem',
        lg: '2rem',
        xl: '3.2rem',
        xxl: '4.4rem',
        default: '1.6rem'
    },
    spacing: {
        100: '0.8rem',
        200: '1.6rem',
        300: '2.4rem',
        400: '3.2rem',
        500: '4rem',
        600: '5.6rem',
        700: '7.2rem',
        800: '8rem',
        900: '9.6rem',
        1000: '12rem'
    },
    borderRadius: {
        xs: '0.4rem',
        sm: '0.8rem',
        lg: '3rem'
    },
    media: {
        xs: '375px',
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
        xxl: '1440px',
        xxxl: '1920px'
    }
};
