import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledCurrencyWrapper = styled.div`
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 4rem;

        @media (min-width: ${lg}) {
            margin-bottom: 0;
            margin-right: 11rem;
        }
    }
`;
