import styled from 'styled-components/macro';

import { StyledText } from '../../../styles';

// STYLING:
const textClr = '#66696f';

export const StyledAddress = styled(StyledText).attrs({ size: 'md' })`
    color: ${textClr};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
