import { Suspense } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { Loader } from '../Loader';

const DEFAULT_LANGUAGE = 'ru';

// The library should lazily load translations by default.
// In order to do this, the application should be wrapped in Suspense.
// If there is a need to disable this behavior, follow this steps
// https://react.i18next.com/latest/usetranslation-hook#not-using-suspense
i18n.use(initReactI18next).init({
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
        escapeValue: false
    }
});

type Props = {
    children: JSX.Element;
};

export const TranslationProvider = ({ children }: Props) => (
    <Suspense fallback={<Loader size="md" fullscreen />}>{children}</Suspense>
);
