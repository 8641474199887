import styled from 'styled-components/macro';

import { StyledWidthLimiter } from '../../../styles';
import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledHeader = styled(StyledWidthLimiter).attrs({ as: 'header' })`
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    @media (min-width: ${lg}) {
        justify-content: flex-start;
        margin-bottom: 5.4rem;
    }
`;
