/**
 * @description
 * Create an async pause
 *
 * @example
 * ```ts
 * const handleSubmit = async () => {
 *     await sleep(5000);
 *
 *     // Do stuff...
 * };
 * ```
 */
export const sleep = (timeout: number) => new Promise(resolve => setTimeout(resolve, timeout));
