import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledPageWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        'sidebar'
        'body'
        'footer';
    gap: 2rem;

    @media (min-width: ${lg}) {
        grid-template-columns: 1fr 40rem;
        grid-template-areas:
            'body sidebar'
            'footer footer';
        gap: 2.2rem;
    }
`;
