import styled, { css } from 'styled-components/macro';

import { ExpandedThemedPropsType } from '../theme';

type Props = ExpandedThemedPropsType<{
    size: 'sm' | 'md' | 'lg';
}>;

const fontSizeMd = ({ theme }: Props) => theme.fontSize.md;
const fontSizeLg = ({ theme }: Props) => theme.fontSize.lg;
const fontSizeXl = ({ theme }: Props) => theme.fontSize.xl;
const lg = ({ theme }: Props) => theme.media.lg;

const applySize = ({ size }: Props) => {
    let font;
    let fontLg;

    switch (size) {
        case 'sm':
            font = fontSizeMd;
            break;

        case 'md':
            font = fontSizeMd;
            fontLg = fontSizeLg;
            break;

        case 'lg':
            font = fontSizeLg;
            fontLg = fontSizeXl;
            break;
    }

    return css`
        font-size: ${font};

        @media (min-width: ${lg}) {
            font-size: ${fontLg};
        }
    `;
};

export const StyledHeading = styled.h1`
    font-weight: 700;
    line-height: 140%;

    ${applySize}
`;
