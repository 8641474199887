import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledHeader = styled.header`
    position: absolute;
    width: 100%;
    padding: 2rem;

    @media (min-width: ${lg}) {
        position: static;
        padding: 2.8rem;
    }
`;
