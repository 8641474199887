const DEFAULT_ZERO_DIGITS_AMOUNT = 18;

/**
 * @description
 * Convert number to a uint256 format
 *
 * @example
 * ```ts
 * const convertedValue = convertNumberToUint256(1.25); // 1250000000000000000
 * ```
 */
export const convertNumberToUint256 = (
    value: number | string,
    zeroDigitsAmount: number = DEFAULT_ZERO_DIGITS_AMOUNT
) => {
    const [integer, decimal = ''] = String(value).split('.');
    // Add missing digits (0) after the dot
    const fixedDecimal = decimal + '0'.repeat(zeroDigitsAmount - decimal.length);

    return [integer, fixedDecimal].join('');
};
