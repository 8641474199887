import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const fontSize = ({ theme }: ThemedPropsType) => theme.fontSize.xxl;

export const StyledDetailsValue = styled.span`
    font-size: ${fontSize};
    font-weight: 700;
`;
