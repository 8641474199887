export type MetaMaskChainParams = {
    chainId: string;
    chainName: string;
    nativeCurrency: {
        name: string;
        symbol: string;
        decimals: number;
    };
    rpcUrls: string[];
    blockExplorerUrls: string[];
};

export type ValidChainId = 56 | 97;

type MetaMaskChainsParams = {
    56: MetaMaskChainParams;
    97: MetaMaskChainParams;
};

export const metamaskChainsParams: MetaMaskChainsParams = {
    // Production
    56: {
        chainId: '0x38',
        chainName: 'Smart Chain',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        rpcUrls: ['https://bsc-dataseed.binance.org/'],
        blockExplorerUrls: ['https://bscscan.com']
    },
    // Development
    97: {
        chainId: '0x61',
        chainName: 'Smart Chain - Testnet',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
        },
        // https://docs.binance.org/smart-chain/developer/rpc.html
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
        blockExplorerUrls: ['https://testnet.bscscan.com']
    }
};
