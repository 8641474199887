import styled from 'styled-components/macro';

import { StyledHeading } from '../../../styles';
import { ThemedPropsType } from '../../../theme';

const lg = ({ theme }: ThemedPropsType) => theme.media.lg;

export const StyledModalTitle = styled(StyledHeading).attrs({ as: 'h3', size: 'lg' })`
    text-align: center;
    margin-bottom: 2.5rem;

    @media (min-width: ${lg}) {
        margin-bottom: 4.6rem;
    }
`;
