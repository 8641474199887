import styled from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const whiteClr = ({ theme }: ThemedPropsType) => theme.color.light[100];
const blackClr = ({ theme }: ThemedPropsType) => theme.color.dark[900];
const fontFamily = ({ theme }: ThemedPropsType) => theme.fontFamily.primary;
const fontSize = ({ theme }: ThemedPropsType) => theme.fontSize.md;

export const StyledBody = styled.p`
    flex-grow: 1;
    padding: 2rem;
    background-color: ${whiteClr};
    font-family: ${fontFamily};
    font-size: ${fontSize};
    font-weight: 500;
    color: ${blackClr};
    word-break: break-word;
`;
