import styled, { keyframes } from 'styled-components/macro';

import { ThemedPropsType } from '../../../theme';

const skChaseDot = keyframes`
    80%,
    100% {
        transform: rotate(360deg);
    }
`;

const skChaseDotBefore = keyframes`
    50% {
        transform: scale(0.4);
    }
    
    100%,
    0% {
        transform: scale(1);
    }
`;

const orangeClr = ({ theme }: ThemedPropsType) => theme.color.primary[500];

export const StyledChaseDot = styled.div.attrs({ tabIndex: 1 })`
    width: 100%;
    height: 100%;
    outline: 0;
    position: absolute;
    left: 0;
    top: 0;
    animation: ${skChaseDot} 2000ms infinite ease-in-out both;

    &:nth-child(1),
    &:nth-child(1)::before {
        animation-delay: -1100ms;
    }

    &:nth-child(2),
    &:nth-child(2)::before {
        animation-delay: -1000ms;
    }

    &:nth-child(3),
    &:nth-child(3)::before {
        animation-delay: -900ms;
    }

    &:nth-child(4),
    &:nth-child(4)::before {
        animation-delay: -800ms;
    }

    &:nth-child(5),
    &:nth-child(5)::before {
        animation-delay: -700ms;
    }

    &:nth-child(6),
    &:nth-child(6)::before {
        animation-delay: -600ms;
    }

    &::before {
        content: '';
        width: 25%;
        height: 25%;
        display: block;
        border-radius: 100%;
        background-color: ${orangeClr};
        animation: ${skChaseDotBefore} 2000ms infinite ease-in-out both;
    }
`;
